import { FormMediaNameListItem } from 'components';
import { BottomSaveCancel } from 'components/BottomSaveCancel';
import { Form } from 'formik';
import React, { useState } from 'react';

import { MediaEditTabs } from './MediaEditTabs';
import { useStyles } from './MediaForm.jss';

export interface MediaFormProps {
  saveInProgress: boolean;
  onCancel?: () => void;
  nameFieldPlaceholder?: string;
}

export const MediaForm: React.FunctionComponent<MediaFormProps> = (props) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const childrenArray = React.Children.toArray(props.children).filter((c) => c !== null);
  const singleChild = childrenArray.length === 1;
  const validChildrenTypes =
    singleChild ||
    childrenArray.reduce<boolean>(
      (a, child) => a && React.isValidElement(child) && (child.type as any).displayName === 'FormTab',
      true
    );
  if (!validChildrenTypes) {
    throw 'MediaForm children can either have a single child or <FormTab> components as children.';
  }

  return (
    <Form className={classes.form}>
      <FormMediaNameListItem placeholder={props.nameFieldPlaceholder} />
      {singleChild ? (
        props.children
      ) : (
        <>
          <MediaEditTabs selectedTab={selectedTab} onSelectedTabChanged={setSelectedTab}>
            {props.children}
          </MediaEditTabs>
          <div className={classes.tabPanel}>{React.Children.toArray(props.children)[selectedTab]}</div>
        </>
      )}
      <BottomSaveCancel saveInProgres={props.saveInProgress} onCancel={props.onCancel} />
    </Form>
  );
};

export interface FormTabProps {
  label: string;
  errorCount?: number;
}
export class FormTab extends React.Component<FormTabProps> {
  constructor(props: FormTabProps) {
    super(props);
  }

  render() {
    if (React.Children.count(this.props.children) === 0) {
      return <div />;
    }
    return this.props.children;
  }
}
(FormTab as any).displayName = 'FormTab';
