import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeTree, TreeWalker, TreeWalkerValue } from 'react-vtree';

import {
  AddressingActions,
  AddressingSelectors,
  AddressingViewModes,
  MediaDeny,
  NodeMeta,
  TreeData,
  TreeNode,
} from '../duck';
import { generateTreeIdentifier } from '../duck/utils';
import useAddressingRules from '../hooks/addressingRules';
import useAddressingStructure from '../hooks/addressingStructure';
import { AddressingNodeRenderer } from './AddressingNode';
import { useStyles } from './AddressingTree.jss';
import { EmptyAddressing } from './EmptyAddressing';

export interface AddressingTreeProps {
  viewMode: AddressingViewModes;
  workgroupId: number;
  channelId: number;
  mediaId: number;
  style?: React.CSSProperties;
  placeholder: JSX.Element;
  nodeRenderer: AddressingNodeRenderer;
  mediaDenies?: MediaDeny[];
}

export const AddressingTree: React.FunctionComponent<AddressingTreeProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tree = useRef<FixedSizeTree<TreeData>>(null);
  const { workgroupId, channelId, mediaId } = props;
  const treeId = generateTreeIdentifier(workgroupId, channelId);
  const addressingStructure = useAddressingStructure(channelId, workgroupId, treeId);
  const addressingRules = useAddressingRules(channelId, mediaId, treeId, props.mediaDenies);
  const canRenderComponent =
    useSelector(AddressingSelectors.selectProcessingComplete(treeId, mediaId)) &&
    addressingRules.addressingStructureInitialized &&
    !addressingRules.needToUpdateTree;
  let componentRendered = useRef(false);

  const toggleExpandNode = useCallback(
    (node: TreeNode) => {
      dispatch(AddressingActions.toggleNodeExpand(node.$$hashKey, treeId, mediaId));
    },
    [treeId, mediaId, dispatch]
  );

  const getNodeData = useCallback(
    (node: TreeNode, nestingLevel: number): TreeWalkerValue<TreeData, NodeMeta> => {
      return {
        data: {
          id: node.$$hashKey,
          isLeaf: node.children.length === 0,
          isOpenByDefault: node.isExpanded,
          label: node.label,
          nestingLevel,
          isExpanded: node.isExpanded,
          chckState: node.chckState,
          modelType: node.modelType,
          isLinked: node.linked ? true : false,
          isProcessing: addressingRules.processingStatus.isProcessing || addressingRules.needToUpdateTree,
          onToggle: () => toggleExpandNode(node),
          onChange: () => addressingRules.onAddressingChange(node),
        },
        nestingLevel,
        node,
      };
    },
    [addressingRules.processingStatus.isProcessing, addressingRules.needToUpdateTree, toggleExpandNode]
  );

  const treeWalker = useCallback(
    function* (): ReturnType<TreeWalker<TreeData, NodeMeta>> {
      // Workgroup View
      //yield getNodeData(addressingStructure.processedData[viewMode][0], 0);

      // A-Z view
      for (let i = 0; i < addressingRules.addressingTree[props.viewMode].length; i++) {
        yield getNodeData(addressingRules.addressingTree[props.viewMode][i], 0);
      }

      // Groups view
      // for (let i =0; i < addressingStructure.processedData[viewMode].length; i++) {
      //   yield getNodeData(addressingStructure.processedData[viewMode][i], 0);
      // }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      while (true) {
        const parentMeta = yield;
        if (parentMeta.node.isExpanded) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < parentMeta.node.children.length; i++) {
            yield getNodeData(parentMeta.node.children[i], parentMeta.nestingLevel + 1);
          }
        }
      }
    },
    [addressingRules.addressingTree, props.viewMode, addressingRules.processingStatus.isProcessing, getNodeData]
  );

  useEffect(() => {
    dispatch(AddressingActions.setAddressingViewMode(props.viewMode, treeId, mediaId));
  }, [props.viewMode, dispatch, treeId]);

  // effect used to clone addressing tree structure for this specific addressing instance
  useEffect(() => {
    const canCloneAddressingStructure = addressingStructure.processingStatus.complete;
    const shouldCloneAddressingStructure = !addressingRules.addressingStructureInitialized;

    if (canCloneAddressingStructure && shouldCloneAddressingStructure) {
      console.info('cloneAddressingStructureToInstance');
      dispatch(AddressingActions.cloneAddressingStructureToInstance(treeId, mediaId));
    }
  }, [treeId, mediaId, addressingStructure.processingStatus.complete, addressingRules.addressingStructureInitialized]);

  // effect used to process addressing rules once the Web Worker and the tree structure have been initialized
  useEffect(() => {
    const canProcessAddressingRules =
      addressingRules.addressingStructureInitialized &&
      addressingRules.fetchStatus.complete &&
      !addressingRules.processingStatus.isProcessing &&
      !addressingRules.processingStatus.complete;
    const shouldProcessAddressingRules = !componentRendered.current;

    if (canProcessAddressingRules && shouldProcessAddressingRules) {
      console.info('processAddressingRulesStart');
      dispatch(AddressingActions.processAddressingRulesStart(addressingRules.rawData, treeId, mediaId));
    }
  }, [
    addressingRules.processingStatus.isProcessing,
    addressingRules.processingStatus.complete,
    addressingRules.fetchStatus.complete,
    addressingRules.needToUpdateTree,
    mediaId,
    treeId,
    addressingRules.addressingStructureInitialized,
  ]);

  // effect used to update the addressing tree when something (a rule) changes
  useEffect(() => {
    const canUpdateAddressingTree =
      !addressingRules.processingStatus.isProcessing &&
      addressingRules.processingStatus.complete &&
      addressingRules.addressingStructureInitialized;

    if (addressingRules.needToUpdateTree && canUpdateAddressingTree) {
      console.info('updateAddressingTree');
      dispatch(AddressingActions.updateAddressingTree(treeId, mediaId));
    }
  }, [
    addressingRules.processedData,
    addressingRules.processingStatus.complete,
    addressingStructure.processedData,
    addressingStructure.processingStatus.complete,
    addressingRules.needToUpdateTree,
    treeId,
    mediaId,
    dispatch,
  ]);

  if (canRenderComponent || componentRendered.current) {
    componentRendered.current = true;
    if (addressingRules.addressingTree[props.viewMode].length < 1) {
      return (
        <div className={classes.noDataContainer}>
          <div className={classes.noDataText}>
            <EmptyAddressing />
          </div>
        </div>
      );
    }
    return (
      <div style={props.style}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <FixedSizeTree ref={tree} treeWalker={treeWalker} itemSize={48} height={height} width={'100%'}>
              {props.nodeRenderer}
            </FixedSizeTree>
          )}
        </AutoSizer>
      </div>
    );
  } else {
    return props.placeholder;
  }
};
