import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { MediaType } from '@models';

interface DropdownButtonProps {
  options: {
    labelKey: string;
    value: MediaType;
  }[];
  onClick: (item: MediaType) => void;
}

export const DropdownButton = ({ onClick, options }: DropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [t] = useTranslation();

  const handleClose = React.useCallback<React.MouseEventHandler<HTMLLIElement>>(
    (e) => {
      setAnchorEl(null);
      if (onClick && e.currentTarget.value) {
        onClick(e.currentTarget.value);
      }
    },
    [onClick, setAnchorEl]
  );

  const handleOpen = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );
  return (
    <>
      <IconButton onClick={handleOpen}>
        <AddIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.labelKey} value={option.value} onClick={handleClose}>
            {t(option.labelKey)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
