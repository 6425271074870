import { IdName } from 'react-tools';

import { MediaDto, MessageType } from '@models';

import { Zone } from '../../zone';

export const libraryAreaName = 'library';
export const formType = 'messageEditForm';

export interface LibraryState {
  items: MessageLibraryItem[];
  selectedItem?: SelectedMessageLibraryItem;
  folderPath: FolderPathItem[];
  fetchingLibrary: boolean;
  fetchingMessage: boolean;
  savingMessage: boolean;
  deletingMessages: boolean;
  creatingMedia: boolean;
}

export interface FolderPathItem {
  id: number;
  name: string;
  level: number;
  messageType?: MessageType;
}

export interface MediaReferencesPayload {
  mediaIds: [];
  OnlySharedReferences: boolean;
}

export interface MessageLibraryItem {
  level: number;
  messageType: MessageType;
  mediaInfo: MediaDto;
  referencedChannels?: Zone[];
}

export interface SelectedMessageLibraryItem {
  item: MessageLibraryItem;
  currentAddressingChannelId?: number;
}

export enum ActionTypes {
  ADD_MESSAGES = 'ADD_MESSAGES',
  CREATE_MEDIA_REQUEST = 'CREATE_MEDIA',
  CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS',
  PUSH_FOLDER = 'PUSH_FOLDER',
  POP_FOLDER = 'POP_FOLDER',
  RESET_ROOT_FOLDER = 'RESET_ROOT_FOLDER',
  FETCH_LIBRARY_REQUEST = 'FETCH_LIBRARY_REQUEST',
  FETCH_LIBRARY_SUCCESS = 'FETCH_LIBRARY_SUCCESS',
  DELETE_MESSAGES_REQUEST = 'DELETE_MESSAGES_REQUEST',
  DELETE_MESSAGES_SUCCESS = 'DELETE_MESSAGES_SUCCESS',
  FETCH_MESSAGE_INFO_REQUEST = 'FETCH_MESSAGE_INFO_REQUEST',
  FETCH_MESSAGE_INFO_SUCCESS = 'FETCH_MESSAGE_INFO_SUCCESS',
  SAVE_MESSAGE_INFO_REQUEST = 'SAVE_MESSAGE_INFO_REQUEST',
  SAVE_MESSAGE_INFO_SUCCESS = 'SAVE_MESSAGE_INFO_SUCCESS',
  SELECT_LIBRARY_ITEM = 'SELECT_LIBRARY_ITEM',
  CLEAR_LIBRARY_SELECTION = 'CLEAR_LIBRARY_SELECTION',
  SELECT_MEDIA_ADDRESSING_CHANNEL = 'SELECT_MEDIA_ADDRESSING_CHANNEL',
}
