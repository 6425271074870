import AddressingService from 'addressing/addressingService';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddressingActions,
  AddressingThunks,
  AddressingSelectors,
  MediaDeny,
  MediaDenyApplyResult,
  TreeItemType,
  TreeNode
} from '../duck';

const useAddressingRules = (channelId: number = 0, mediaId: number = 0, treeId: string, mediaDenies?: MediaDeny[]) => {
  const dispatch = useDispatch();
  //const addressingRulesState = useSelector(AddressingSelectors.selectAddressingRules(treeId));
  const addressingInstanceState = useSelector(AddressingSelectors.selectAddressingInstance(treeId, mediaId));

  const applyRules = useCallback(
    (newDenies: MediaDeny[]) => {
      dispatch(AddressingActions.processAddressingRulesStart(newDenies, treeId, mediaId));
    },
    [treeId, dispatch]
  );

  const onRuleChanged = useCallback(
    (item: TreeNode) => {
      if (item.chckState === 4) {
        return;
      }

      if (item.modelType === TreeItemType.FOLDER) {
        return;
      }

      const channelMediaDenyInfo: MediaDeny = {
        idMediaChannel: channelId,
        idMedia: mediaId,
        idEntity: Number(item.cleanId),
        idEntityType: item.entityType,
        deny: item.chckState === 0 || item.chckState === 3 ? false : item.chckState === 1 ? true : null,
      };

      applyRules([channelMediaDenyInfo]);
    },
    [channelId, mediaId, applyRules]
  );

  // effect used to init addressing rules data
  useEffect(() => {
    // if there's no state for this addressing instance, create one
    if (!addressingInstanceState) {
      dispatch(AddressingActions.initAddressingInstance(treeId, mediaId));
    }
  }, [addressingInstanceState, channelId, mediaId, treeId]);

  // subscribe to the Web Worker so it can send us back the processed rules
  useEffect(() => {
    // if there's not state for this addressing instance (treeId) abort
    if (!addressingInstanceState) {
      return;
    }

    if (addressingInstanceState.subscribedToAddressingWorker) {
      return;
    }

    AddressingService.subscribeToAddressingWorker((result: MediaDenyApplyResult, requestId: string) => {
      dispatch(AddressingActions.processAddressingRulesEnd(result, treeId, mediaId, requestId));
    }, treeId, mediaId);

    dispatch(AddressingActions.subscribeToAddressingWorker(treeId, mediaId));
  }, [addressingInstanceState, channelId, mediaId]);

  // effect used for initial data retrieval
  useEffect(() => {
    // if there's not state for this addressing instance (treeId) abort
    if (!addressingInstanceState) {
      return;
    }

    const isFetching = addressingInstanceState.addressingRules.fetchStatus.isFetching;
    const fetchComplete = addressingInstanceState.addressingRules.fetchStatus.complete;
    const fetchError = addressingInstanceState.addressingRules.fetchStatus.error;

    // we need to fetch addressing rules
    if (!isFetching && !fetchComplete && (fetchError === '')) {
      // if we provided mediaDenies from the outside, just use those
      if (mediaDenies) {
        dispatch(AddressingActions.fetchAddressingRulesSuccess(channelId, mediaId, mediaDenies, treeId));
        return;
      } else {
        // if we didn't provide deny rules from the outside, go and fetch them from the server
        dispatch(AddressingThunks.fetchAddressingRules(channelId, mediaId, treeId));
      }
    }
  }, [
    addressingInstanceState,
    channelId,
    mediaId,
  ]);

  return {
    processingStatus: addressingInstanceState?.addressingRules.processingStatus,
    fetchStatus: addressingInstanceState?.addressingRules.fetchStatus,
    rawData: addressingInstanceState?.addressingRules.originalData,
    processedData: addressingInstanceState?.addressingRules.processedData,
    needToUpdateTree: addressingInstanceState?.needToUpdateTree,
    addressingTree: addressingInstanceState?.addressingTree,
    mediaId: mediaId,
    addressingStructureInitialized: addressingInstanceState?.addressingTree.length > 0,
    onAddressingChange: onRuleChanged,
  };
};

export default useAddressingRules;
