import { FormikSlider } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { TextFieldInnerWrapper } from 'react-tools';
import { EnumUtils } from 'utils';
import * as yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { MediaUpdateDto, PlayerStreamType } from '@models';
import { MediaType } from '@models/media/MediaType';
import { AdProvider } from '@models/media/mediaTypes/Ad';

import { useStyles } from './NewAdDialog.jss';

interface NewAdFormValues {
  name: string;
  provider: AdProvider;
  accountName: string;
  alias: string;
  contentType: PlayerStreamType;
  volume: number;
}

const initialFormValues = (t: TFunction): NewAdFormValues => ({
  name: t('media.ad.field.name.placeholder'),
  provider: AdProvider.AdsWizz,
  accountName: t('media.ad.field.accountName.placeholder'),
  alias: '',
  contentType: PlayerStreamType.Audio,
  volume: 100,
});

const adFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(i18next.t('media.validators.name'))
    .max(255, i18next.t('media.validators.maxLength', { length: 255 })),
  provider: yup.number(),
  accountName: yup
    .string()
    .required(i18next.t('media.ad.field.accountName.missing.error'))
    .max(255, i18next.t('media.validators.maxLength', { length: 255 })),
  alias: yup
    .string()
    .required(i18next.t('media.ad.field.alias.missing.error'))
    .max(255, i18next.t('media.validators.maxLength', { length: 255 })),
  contentType: yup.number(),
  volume: yup
    .number()
    .min(0, i18next.t('media.validators.minVolume', { min: 0 }))
    .max(100, i18next.t('media.validators.maxVolume', { max: 100 })),
});

export interface NewAdDialogProps {
  open: boolean;
  onSave: (values: MediaUpdateDto) => void;
  onCancel: () => void;
}

export const NewAdDialog: React.FunctionComponent<NewAdDialogProps> = (props) => {
  const { open, onSave, onCancel } = props;
  const [t] = useTranslation();
  const classes = useStyles();
  const onFormSubmit = useCallback(
    (values: NewAdFormValues) => {
      const mediaUpdateDto: MediaUpdateDto = {
        id: 0,
        mediaType: MediaType.Ad,
        name: values.name,
        description: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        diffusionDays: 127,
        timezone: '',
        endMedia: false,
        mediaFolderId: 1,
        properties: {
          id: 0,
          accountName: values.accountName,
          adProps: {
            alias: values.alias,
            duration: 60000,
            maxAds: 1,
            country: 0,
            category: [],
            customProps: [],
            excluded: 0,
            language: 0,
            x: 0,
            y: 0,
          },
          provider: values.provider,
          soundVolume: values.volume,
          streamType: 1,
        },
      };
      onSave(mediaUpdateDto);
    },
    [onSave]
  );
  return (
    <Dialog open={open} keepMounted={false}>
      <Formik initialValues={initialFormValues(t)} validationSchema={adFormSchema} onSubmit={onFormSubmit}>
        <Form>
          <DialogTitle>{t('media.ad.newDialog.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('media.ad.newDialog.message')}</DialogContentText>
            <NewAdFormFields />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

const NewAdFormFields: React.FunctionComponent = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const nameInputRef = useRef<HTMLInputElement>();
  const accountRef = useRef<HTMLInputElement>();
  const aliasRef = useRef<HTMLInputElement>();
  const [cursor, setCursor] = useState({ index: 0 });

  useEffect(() => {
    if (nameInputRef.current) setTimeout(() => nameInputRef.current?.select());
  }, [nameInputRef]);

  // effect used to reposition the cursor after manipulating input value in onChange
  useEffect(() => {
    const ref =
      accountRef.current === document.activeElement
        ? accountRef
        : aliasRef.current === document.activeElement
        ? aliasRef
        : undefined;

    if (ref && ref.current && cursor.index) {
      ref.current.selectionStart = ref.current.selectionEnd = cursor.index - 1;
    }
  });

  const ignoreSpacesOnChange = useCallback((fProps: FieldProps) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;

      if (value.includes(' ')) {
        setCursor({ index: e.target.selectionStart || 0 });
        e.target.value = value.replace(/\s+/g, '');
      }

      fProps.field.onChange(e);
    };

    return onChange;
  }, []);

  return (
    <>
      <Field name="name">
        {(fProps: FieldProps) => (
          <TextFieldInnerWrapper
            fullWidth
            inputRef={nameInputRef}
            autoFocus
            className={classes.formItem}
            inputProps={{ maxLength: 255 }}
            variant="filled"
            name={fProps.field.name}
            label={t('media.ad.field.name')}
            error={fProps.meta.error !== undefined}
            value={fProps.field.value}
            onChange={fProps.field.onChange}
            onBlur={fProps.field.onBlur}
            helperText={fProps.meta.error}
          />
        )}
      </Field>
      <Field name="provider">
        {(fProps: FieldProps) => (
          <FormControl className={classes.formItem} fullWidth variant="filled" size="small">
            <InputLabel id="provider-label">{t('media.ad.field.provider')}</InputLabel>
            <Select disabled labelId="provider-label" id="provider" value={fProps.field.value} name={fProps.field.name}>
              {EnumUtils.enumToMenuItems(AdProvider, 'media.ad.providers')}
            </Select>
          </FormControl>
        )}
      </Field>
      <Field name="accountName">
        {(fProps: FieldProps) => (
          <TextField
            inputRef={accountRef}
            fullWidth
            className={classes.formItem}
            inputProps={{ maxLength: 50 }}
            variant="filled"
            name={fProps.field.name}
            label={t('media.ad.field.accountName')}
            error={fProps.meta.error !== undefined}
            value={fProps.field.value}
            onChange={ignoreSpacesOnChange(fProps)}
            onBlur={fProps.field.onBlur}
            helperText={fProps.meta.error}
          />
        )}
      </Field>
      <Field name="alias">
        {(fProps: FieldProps) => (
          <TextField
            inputRef={aliasRef}
            fullWidth
            className={classes.formItem}
            inputProps={{ maxLength: 255 }}
            variant="filled"
            name={fProps.field.name}
            label={t('media.ad.field.alias')}
            error={fProps.meta.error !== undefined}
            value={fProps.field.value}
            onChange={ignoreSpacesOnChange(fProps)}
            onBlur={fProps.field.onBlur}
            helperText={fProps.meta.error}
          />
        )}
      </Field>
      <Field name="contentType">
        {(fProps: FieldProps) => (
          <FormControl className={classes.formItem} fullWidth variant="filled" size="small">
            <InputLabel id="contentType-label">{t('media.ad.field.contentType')}</InputLabel>
            <Select
              disabled
              labelId="contentType-label"
              id="contentType"
              value={fProps.field.value}
              name={fProps.field.name}
            >
              {EnumUtils.enumToMenuItems(PlayerStreamType, 'media.ad.contentTypes')}
            </Select>
          </FormControl>
        )}
      </Field>
      <FormControl className={classes.formItem} fullWidth variant="filled" size="small">
        <InputLabel id="volume-label">{t('media.ad.field.volume')}</InputLabel>
        <FormikSlider id="volume" fieldName="volume" min={0} max={100} />
      </FormControl>
    </>
  );
};
