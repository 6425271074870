import { MSG } from 'App.bootstrap';
import i18next from 'i18next';
import { pushNotification } from 'react-tools';
import { Dispatch } from 'redux';
import { Sanitization } from 'utils';

import { MediaUpdateDto, MessageType } from '@models';

import { AddressingActions, AddressingApi, MediaDeny } from '../../addressing';
import * as actions from './actions';
import { LibraryApi } from './api';
import { MediaReferencesPayload } from './types';

export const fetchLibrary = (selectedMessageType: MessageType, workgroupId: number = MSG.workgroupId) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchLibraryRequest(workgroupId));
    try {
      const messages = await LibraryApi.getLibrary(workgroupId);
      dispatch(actions.fetchLibrarySuccess(workgroupId, messages, selectedMessageType));
    } catch (err) {}
  };
};

export const fetchMessage = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchMessageInfoRequest(id));
    try {
      const mediaDto = await LibraryApi.getMessageInfo(id);
      // const referencedChannels = await ZonesApi.getMediaChannelReferences(id);
      dispatch(actions.fetchMessageInfoSuccess(mediaDto, []));
      dispatch(actions.selectLibraryItem(id));
    } catch (err) {}
  };
};

export const deleteMessages = (mediaIds: number[]) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.deleteMessagesRequest(mediaIds));
    try {
      var references = await LibraryApi.mediaReferences(<MediaReferencesPayload>{
        OnlySharedReferences: false,
        mediaIds: mediaIds,
      });
      if (references) {
        var playlists = references['playlist'];
        var playlistNames = '';
        if (playlists !== undefined) {
          playlistNames = playlists.join(', ');
        }
        dispatch(pushNotification(i18next.t('denyDeleteMessageTitle'), 'error'));
      } else {
        await LibraryApi.deleteMessages(mediaIds);
        dispatch(actions.deleteMessagesSuccess(mediaIds));
      }
    } catch (err) {}
  };
};

export const createMedia = (messageType: MessageType, media: MediaUpdateDto) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.createMediaRequest(messageType, media));
    try {
      const newMedia = await LibraryApi.createMedia(media);
      dispatch(actions.createMediaSuccess(messageType, newMedia));
    } catch (e) {
      console.log(e);
      dispatch(pushNotification(i18next.t('error.saveMessage'), 'error'));
    }
  };
};

export const saveMessage = (media: MediaUpdateDto, addressing: {treeId: string, rules: MediaDeny[]}) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.saveMessagesRequest(media));
    try {
      // before saving the actual message we first save the addressing rules
      // because message saving also takes care of publishing/apply changes
      if (addressing.rules.length > 0) {
        await AddressingApi.updateAddressing(addressing.rules);
        dispatch(AddressingActions.saveAddressingRules(addressing.treeId, media.id))
      }

      await LibraryApi.saveMessages(media, addressing.rules.length === 0);

      // lastly publish the channel
      if (addressing.rules.length > 0) {
        await LibraryApi.publishZones([addressing.rules[0].idMediaChannel]);
      }

      dispatch(actions.saveMessagesSuccess(media));
      dispatch(pushNotification(i18next.t('success.saveMessage'), 'success'));
    } catch (err) {
      console.log(err);
      dispatch(pushNotification(i18next.t('error.saveMessage'), 'error'));
    }
  };
};

// not actually a thunk, but I don't want to import the API in the Navigator component
export const getAdProviderState = async (): Promise<boolean> => {
    try {
      const status = await LibraryApi.getAdProviderStatus();
      return status;
    } catch (err) {
      //TODO: ???
    }

    return false;
};
