import HelperWorker from 'worker-loader!./addressingHelper'; // eslint-disable-line import/no-webpack-loader-syntax
import AddressingWorker from 'worker-loader!./addressingWorker'; // eslint-disable-line import/no-webpack-loader-syntax

import {
  AddressingHelperWorkerResponse,
  AddressingStructureResponse,
  MediaDeny,
  MediaDenyApplyResult,
  MediaDenyApplyWorkerResponse,
} from './duck';

const INIT_ADDRESSING_WORKER = 'request_buckets_init';
const INIT_HELPER_WORKER = INIT_ADDRESSING_WORKER;
const PROCESS_ADDRESSING_RULES = 'request_apply_addressing_rules';
const PROCESS_ADDRESSING_RULES_COMPLETE = 'response_apply_addressing_rules';
const PROCESS_ADDRESSING_STRUCTURE_COMPLETE = 'response_view_modes_build_complete';

class AddressingService {
  private addressingRegistry: {
    [key: string]: {
      worker: AddressingWorker;
      listeners: { [key: number]: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void };
    };
  };
  private helpersRegistry: { [key: string]: HelperWorker };
  private static instance: AddressingService;

  private constructor() {
    this.addressingRegistry = {};
    this.helpersRegistry = {};
  }

  public static getInstance() {
    if (!AddressingService.instance) {
      AddressingService.instance = new AddressingService();
    }

    return AddressingService.instance;
  }

  public initAddressingWorker(data: AddressingStructureResponse[], currentWorkgroupId: number, treeId: string): void {
    if (!this.addressingRegistry[treeId]) {
      this.addressingRegistry[treeId] = { worker: new AddressingWorker(), listeners: {} };
    }

    this.publish(
      {
        message: INIT_ADDRESSING_WORKER,
        data: {
          result: data,
          currentWorkgroupID: currentWorkgroupId,
        },
      },
      this.addressingRegistry[treeId].worker
    );
  }

  public initAddressingStructure(
    data: AddressingStructureResponse[],
    currentWorkgroupId: number,
    treeId: string
  ): void {
    if (!this.helpersRegistry[treeId]) {
      this.helpersRegistry[treeId] = new HelperWorker();
    }

    const id = +new Date();

    this.publish(
      {
        message: INIT_HELPER_WORKER,
        requestId: id.toString(),
        data: {
          result: data,
          currentWorkgroupID: currentWorkgroupId,
          treeId,
        },
      },
      this.helpersRegistry[treeId]
    );
  }

  public applyRules(rules: MediaDeny[], treeId: string): string {
    const requestId = new Date().valueOf().toString();
    this.publish(
      {
        message: PROCESS_ADDRESSING_RULES,
        requestId: requestId,
        mediaName: '',
        data: {
          denyRules: rules,
        },
      },
      this.addressingRegistry[treeId].worker
    );

    return requestId;
  }

  public subscribeToAddressingWorker(
    callback: (result: MediaDenyApplyResult, requestId: string) => void,
    treeId: string,
    mediaId: number
  ) {
    console.info(`Subscribed to AddressingWorker: ${treeId}`);
    this.subscribeToWorker(
      (response: { data: MediaDenyApplyWorkerResponse }) => {
        if (response.data.message === PROCESS_ADDRESSING_RULES_COMPLETE) {
          return callback(response.data.data, response.data.requestId);
        }
      },
      treeId,
      mediaId
    );
  }

  public subscribeToHelperWorker(callback: (result: string) => void, treeId: string) {
    console.info(`Subscribed to HelperWorker: ${treeId}`);
    this.subscribeToHelper((response: { data: AddressingHelperWorkerResponse }) => {
      if (response.data.message === PROCESS_ADDRESSING_STRUCTURE_COMPLETE) {
        return callback(response.data.data);
      }
    }, treeId);
  }

  public removeAddressingWorker(treeId: string) {
    if (this.addressingRegistry[treeId]) {
      this.addressingRegistry[treeId].worker.terminate();
      delete this.addressingRegistry[treeId];
    }
  }

  public removeHelperWorker(treeId: string) {
    if (this.helpersRegistry[treeId]) {
      this.helpersRegistry[treeId].terminate();
      delete this.helpersRegistry[treeId];
    }
  }

  private publish(message: any, worker: Worker) {
    worker.postMessage(message);
  }

  private subscribeToWorker(
    handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void,
    treeId: string,
    mediaId: number
  ) {
    if (!this.addressingRegistry[treeId]) {
      this.addressingRegistry[treeId] = { worker: new AddressingWorker(), listeners: {} };
    }

    this.addressingRegistry[treeId].worker.addEventListener('message', handler);
    this.addressingRegistry[treeId].listeners[mediaId] = handler;
  }

  private subscribeToHelper(handler: (response: MessageEvent<AddressingHelperWorkerResponse>) => void, treeId: string) {
    if (!this.helpersRegistry[treeId]) {
      this.helpersRegistry[treeId] = new HelperWorker();
    }

    this.helpersRegistry[treeId].addEventListener('message', handler);
  }

  public unSubscribeFromAddressingWorker(treeId: string, mediaId: number) {
    if (!this.addressingRegistry[treeId]?.listeners?.[mediaId]) {
      return;
    }

    const handler = this.addressingRegistry[treeId].listeners[mediaId];
    this.addressingRegistry[treeId].worker.removeEventListener('message', handler);
    delete this.addressingRegistry[treeId].listeners[mediaId];
  }
}

export default AddressingService.getInstance();
