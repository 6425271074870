import { MediaType } from '@models';
import { PlayerStreamType } from '@models/PlayerStreamType';

import { MediaBaseDto, MediaUpdateBaseDto } from '../MediaDto';

export enum AdProvider
{
    AdsWizz = 0,
    ViStar = 1
}

export enum AdsWizzLanguage
{
    English = 0,
    Spanish = 1,
}

export enum AdsWizzCountry
{
    USA = 0,
}

export interface AdCustomProp {
  id: number;
  key: string;
  value: string;
}

export interface AdMediaDto extends MediaBaseDto {
  mediaType: MediaType.Ad;
  properties: AdProperties;
}

export interface AdMediaUpdateDto extends MediaUpdateBaseDto {
  mediaType: MediaType.Ad;
  properties: AdProperties;
}

export interface AdProperties {
  accountName: string;
  adProps: {
    alias: string,
    category: string[],
    country: number,
    customProps: AdCustomProp[],
    duration: number,
    excluded: number
    language: number,
    maxAds: number,
    x: number,
    y: number
  },
  id: number,
  provider: number,
  soundVolume: number,
  streamType: PlayerStreamType
}
