import React from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { FormListItemText } from './FormListItemText';

export interface FormListSwitchItemProps {
  labelKey: string;
  value: React.ReactElement | string;
  on: boolean;
  noValueKey?: string;
  icon?: React.ReactElement;
  onSwitchChange: (on: boolean) => void;
}

export const FormListSwitchItem: React.FunctionComponent<FormListSwitchItemProps> = (props) => {
  const [t] = useTranslation();
  const { on, onSwitchChange } = props;
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSwitchChange(event.target.checked);
    },
    [onSwitchChange]
  );
  

  return (
    <>
      <ListItem>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <FormListItemText
          focused={false}
          labelKey={props.labelKey}
          value={props.value}
          noValueKey={props.noValueKey}
          inset={!props.icon}
        />
        <Switch checked={on} onChange={handleChange} />
      </ListItem>
      <Collapse in={on} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </>
  );
};
