import { useField } from 'formik';
import React, { useEffect, useState } from 'react';

import { FilledInput, Input, Slider, SliderProps } from '@material-ui/core';

import { useStyles } from './FormikSlider.jss';

export interface FormikSliderProps extends SliderProps {
  fieldName: string;
}

export const FormikSlider: React.FunctionComponent<FormikSliderProps> = (props) => {
  const { fieldName, ...sliderProps } = props;
  const [field, meta, helpers] = useField(fieldName);
  const [value, setValue] = useState(field.value);
  const classes = useStyles();
  const onChangeCommited = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      helpers.setValue(value as number);
    },
    [helpers]
  );
  const innerOnChange = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      setValue(value as number);
    },
    [setValue]
  );

  const onFieldChanged = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    const newValue = Number(e.target.value);
    if (Number.isNaN(newValue)) {
      return;
    }

    if ((props.min !== undefined && newValue < props.min) || (props.max !== undefined && newValue > props.max)) {
      return;
    }

    setValue(newValue);
    helpers.setValue(newValue as number);
  }, [helpers, props.min, props.max]);

  useEffect(() => {
    if (field.value !== undefined) {
      setValue(field.value);
    } else {
      setValue(0);
    }
  }, [field.value]);

  return (
    <div className={classes.root}>
      <Slider {...sliderProps} value={value ?? 0} onChange={innerOnChange} onChangeCommitted={onChangeCommited} min={props.min} max={props.max} />
      <FilledInput
        inputProps={{min: props.min, max: props.max}}
        type="numeric"
        disableUnderline
        className={classes.field}
        value={value ?? 0}
        onChange={onFieldChanged}
        classes={{root: classes.fieldRoot, input: classes.fieldInput}}
      />
    </div>
  );
};
