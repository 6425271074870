import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectProps
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export interface FormSelectListItemOption {
  value: number;
  name: string;
}

export interface FormSelectListItemProps {
  fieldName: string;
  label: string;
  icon?: React.ReactElement;
  options: FormSelectListItemOption[];
  SelectProps?: SelectProps;
}

export const FormSelectListItem: React.FunctionComponent<FormSelectListItemProps> = (props) => {
  const [field, _, helpers] = useField(props.fieldName);
  const onChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
      helpers.setValue(event.target.value);
    },
    [helpers.setValue]
  );
  const [t] = useTranslation();
  return (
    <ListItem>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText disableTypography>
        <FormControl fullWidth variant="filled">
          <InputLabel id={`${props.fieldName}-label`}>{t(props.label)}</InputLabel>
          <Select
            {...props.SelectProps}
            labelId={`${props.fieldName}-label`}
            name={props.fieldName}
            value={field.value ?? 0}
            onChange={onChange}
          >
            {props.options.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItemText>
    </ListItem>
  );
};
