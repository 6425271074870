import { format, isAfter, isEqual, isValid, parseISO, parseJSON, startOfDay, differenceInMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import i18next from 'i18next';
import { convertHMSToSeconds, secondsFormatForRepeatFrequency } from 'utils/dateTimeUtils';
import * as yup from 'yup';

import { MediaDto, MediaProperties, MediaTypes, MessageType } from '@models';
import { MediaType } from '@models/media/MediaType';

export interface MediaFormData {
  name: string;
  description: string;
  scheduling: {
    startDate: Date;
    endDate: Date;
    startTime: Date;
    endTime: Date;
    diffusionDays: number;
  };
  duration: number | undefined;
  properties: MediaProperties;
}

export interface PlaylistFormData extends MediaFormData {
  eventType: MediaTypes.Playlist.EventType;
  repeatFrequency: MediaTypes.Playlist.RepeatFrequency;
  isRandom: boolean;
  maxNumberOfMessages: number;
  repeatNumberOfTracks: number | undefined;
  repeatInterval?: number | undefined;
}

export const mediaFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(i18next.t('media.validators.name'))
    .max(255, i18next.t('media.validators.maxLength', { length: 255 })),
  description: yup.string().max(500, i18next.t('media.validators.maxLength', { length: 500 })),
  scheduling: yup.object().shape({
    startDate: yup.date().required().typeError(i18next.t('media.validators.invalidDate')),
    endDate: yup
      .date()
      .required()
      .when('startDate', (startDate: Date, schema: yup.DateSchema) =>
        schema.test('minDate', i18next.t('media.validators.scheduling.date'), (endDate: Date | undefined) => {
          if (!isValid(startDate) || !isValid(endDate)) {
            return true;
          }

          //@ts-ignore
          return isEqual(endDate, startDate) || isAfter(endDate, startDate);
        })
      )
      .typeError(i18next.t('media.validators.invalidDate')),
    startTime: yup.date().required().typeError(i18next.t('media.validators.invalidDate')),
    endTime: yup
      .date()
      .required()
      .when('startTime', (startTime: Date, schema: yup.DateSchema) =>
        schema.test('minTime', i18next.t('media.validators.scheduling.time'), (endTime: Date | undefined) => {
          if (!isValid(startTime) || !isValid(endTime)) {
            return true;
          }

          const normalizedStartTime = new Date();
          normalizedStartTime.setHours(startTime.getHours());
          normalizedStartTime.setMinutes(startTime.getMinutes());
          normalizedStartTime.setSeconds(startTime.getSeconds());
          normalizedStartTime.setMilliseconds(0);

          const normalizedEndTime = new Date();
          if (endTime) {
            normalizedEndTime.setHours(endTime.getHours());
            normalizedEndTime.setMinutes(endTime.getMinutes());
            normalizedEndTime.setSeconds(endTime.getSeconds());
            normalizedEndTime.setMilliseconds(0);
          }

          //@ts-ignore
          const after = differenceInMinutes(normalizedEndTime, normalizedStartTime);
          return after > 0;
        })
      )
      .typeError(i18next.t('media.validators.invalidDate')),
    diffusionDays: yup.number().min(1, i18next.t('media.validators.scheduling.diffusionDays')),
  }),
});

export const editAdProviderFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(i18next.t('media.validators.name'))
    .max(255, i18next.t('media.validators.maxLength', { length: 255 })),
  description: yup.string().max(500, i18next.t('media.validators.maxLength', { length: 500 })),
  scheduling: yup.object().shape({
    startDate: yup.date().required().typeError(i18next.t('media.validators.invalidDate')),
    endDate: yup
      .date()
      .required()
      .when('startDate', (startDate: Date, schema: yup.DateSchema) =>
        schema.test('minDate', i18next.t('media.validators.scheduling.date'), (endDate: Date | undefined) => {
          if (!isValid(startDate) || !isValid(endDate)) {
            return true;
          }

          //@ts-ignore
          return isEqual(endDate, startDate) || isAfter(endDate, startDate);
        })
      )
      .typeError(i18next.t('media.validators.invalidDate')),
    startTime: yup.date().required().typeError(i18next.t('media.validators.invalidDate')),
    endTime: yup
      .date()
      .required()
      .when('startTime', (startTime: Date, schema: yup.DateSchema) =>
        schema.test('minTime', i18next.t('media.validators.scheduling.time'), (endTime: Date | undefined) => {
          if (!isValid(startTime) || !isValid(endTime)) {
            return true;
          }

          //@ts-ignore
          const after = isAfter(endTime, startTime);
          return after;
        })
      )
      .typeError(i18next.t('media.validators.invalidDate')),
    diffusionDays: yup.number().min(1, i18next.t('media.validators.scheduling.diffusionDays')),
  }),
  properties: yup.object().shape({
    accountName: yup
      .string()
      .trim()
      .required(i18next.t('media.ad.field.accountName.missing.error'))
      .max(50, i18next.t('media.validators.maxLength', { length: 50 })),
    adProps: yup.object().shape({
      alias: yup
        .string()
        .trim()
        .required(i18next.t('media.ad.field.alias.missing.error'))
        .max(255, i18next.t('media.validators.maxLength', { length: 255 })),
      customProps: yup.array(
        yup.object().shape({
          id: yup.number().required(),
          key: yup
            .string()
            .required(i18next.t('media.ad.field.customProps.missingKey.error'))
            .trim()
            .max(50, i18next.t('media.validators.maxLength', { length: 50 })),
          value: yup
            .string()
            .required(i18next.t('media.ad.field.customProps.missingValue.error'))
            .trim()
            .max(50, i18next.t('media.validators.maxLength', { length: 50 })),
        })
      ),
    }),
  }),
});

export const eventPlaylistFormSchema = mediaFormSchema.concat(
  yup.object().shape({
    eventType: yup.number().required(),
    repeatFrequency: yup.number().required(),
    isRandom: yup.boolean(),
    maxNumberOfMessages: yup.number().min(0),
    repeatNumberOfTracks: yup
      .number()
      .when('repeatFrequency', (repeatFrequency: MediaTypes.Playlist.RepeatFrequency, schema: yup.NumberSchema) =>
        repeatFrequency === MediaTypes.Playlist.RepeatFrequency.RepeatAfterNumberOfTracks
          ? schema.required('media.validators.scheduling.repeatNumberOfTracks').min(1).max(100)
          : schema.notRequired()
      ),
    repeatInterval: yup
      .number()
      .when('repeatFrequency', (repeatFrequency: MediaTypes.Playlist.RepeatFrequency, schema: yup.NumberSchema) => {
        return repeatFrequency === MediaTypes.Playlist.RepeatFrequency.RepeatHourly
          ? schema.required('media.validators.scheduling.repeatInterval').min(1).max(86340)
          : schema.notRequired();
      }),
  })
);

export const loopPlaylistFormSchema = mediaFormSchema.concat(
  yup.object().shape({
    isRandom: yup.boolean(),
  })
);

const getDefaultEndDate = () => {
  let now = new Date();
  now.setFullYear(2099);
  now = startOfDay(now);
  return now;
};

export const getDefaultStartTime = () => parseISO('0001-01-01T00:00:00');

export const getDefaultEndTime = () => parseISO('0001-01-01T23:59:59');

export const mediaDtoToFormData = (media: MediaDto | undefined): MediaFormData => {
  const formData = {
    name: media ? media.name : '',
    description: media ? media.description : '',
    scheduling: {
      startDate: media
        ? media.startDate
          ? utcToZonedTime(parseJSON(media.startDate), 'UTC')
          : new Date()
        : new Date(),
      endDate: media
        ? media.endDate
          ? utcToZonedTime(parseJSON(media.endDate), 'UTC')
          : getDefaultEndDate()
        : getDefaultEndDate(),
      startTime: media ? parseISO('0001-01-01T' + media.startTime) : getDefaultStartTime(),
      endTime: media ? parseISO('0001-01-01T' + media.endTime) : getDefaultEndTime(),
      diffusionDays: media ? media.diffusionDays : 127,
    },
    duration: media ? media.duration : undefined,
    properties: media ? media.properties : undefined,
  };
  return formData;
};

export const playlistToFormData = (playlist?: MediaTypes.Playlist.PlaylistDto): PlaylistFormData => {
  return playlist
    ? {
        ...mediaDtoToFormData(playlist),
        eventType: playlist.eventType,
        repeatFrequency: playlist.repeatFrequency,
        isRandom: playlist.isRandom,
        maxNumberOfMessages: playlist.maxNoOfElements,
        repeatNumberOfTracks: playlist.repeatNumberOfTracks,
        repeatInterval: convertHMSToSeconds(playlist.repeatInterval),
      }
    : {
        ...mediaDtoToFormData(playlist),
        eventType: MediaTypes.Playlist.EventType.AsSoonAs,
        repeatFrequency: MediaTypes.Playlist.RepeatFrequency.RepeatDaily,
        isRandom: false,
        maxNumberOfMessages: 0,
        repeatNumberOfTracks: undefined,
        repeatInterval: undefined,
      };
};

export const playlistFormDataToCreateDto = (
  playlistInfo: PlaylistFormData,
  messageType?: MessageType,
  playlistId?: number
): MediaTypes.Playlist.PlaylistUpdateDto => ({
  owner: '',
  created: new Date(),
  startDate: format(playlistInfo.scheduling.startDate, 'yyyy-MM-dd 00:00:00'),
  endDate: format(playlistInfo.scheduling.endDate, 'yyyy-MM-dd 00:00:00'),
  startTime: playlistInfo.scheduling.startTime ? format(playlistInfo.scheduling.startTime, 'HH:mm:ss') : '00:00:00',
  endTime: playlistInfo.scheduling.endTime ? format(playlistInfo.scheduling.endTime, 'HH:mm:ss') : '00:00:00',
  diffusionDays: playlistInfo.scheduling.diffusionDays,
  isLocalInput: false,
  timezone: '',
  endMedia: false,
  origin: 1,
  description: playlistInfo.description,
  eventType: messageType === MessageType.Overhead ? playlistInfo.eventType : undefined,
  name: playlistInfo.name,
  repeatFrequency: playlistInfo.repeatFrequency,
  id: playlistId,
  repeatInterval: playlistInfo.repeatInterval
    ? secondsFormatForRepeatFrequency(playlistInfo.repeatInterval)
    : undefined,
  repeatNumberOfTracks: playlistInfo.repeatNumberOfTracks,
  maxNoOfElements: playlistInfo.maxNumberOfMessages,
  isRandom: playlistInfo.isRandom,
  mediaType: messageType === MessageType.Overhead ? MediaType.EventPlaylist : MediaType.LoopPlaylist,
});
