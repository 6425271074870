import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeUtils } from 'utils';

import { TextField } from '@material-ui/core';

import { useStyles } from './DurationFields.jss';

type DurationComponent = 'hours' | 'minutes' | 'seconds';

export const DurationFields: React.FunctionComponent<{
  value: number;
  setValue: (value: number) => void;
  classes?: { root?: string };
}> = (props) => {
  const { value, setValue } = props;
  const classes = useStyles();
  const [hours, minutes, seconds] = DateTimeUtils.getHMSFromMS(value ? value : 0);

  const onFieldChange = useCallback(
    (name: DurationComponent, value: number) => {
      let ms = 0;
      switch (name) {
        case 'hours':
          ms = DateTimeUtils.getMSFromHMS(value, minutes, seconds);
          break;
        case 'minutes':
          ms = DateTimeUtils.getMSFromHMS(hours, value, seconds);
          break;
        case 'seconds':
          ms = DateTimeUtils.getMSFromHMS(hours, minutes, value);
          break;
      }
      setValue(ms);
    },
    [hours, minutes, seconds, setValue]
  );

  return (
    <div className={classNames(classes.intervalWrapper, [props.classes?.root])}>
      <DurationField name="hours" max={23} value={hours} onChange={onFieldChange} />
      <DurationField name="minutes" max={59} value={minutes} onChange={onFieldChange} />
      <DurationField name="seconds" max={59} value={seconds} onChange={onFieldChange} />
    </div>
  );
};

const DurationField: React.FunctionComponent<{
  name: DurationComponent;
  value: number;
  min?: number;
  max: number;
  onChange: (name: DurationComponent, value: number) => void;
}> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { name, onChange, value, min = 0, max } = props;

  if (min > max) {
    throw new Error('Cannot have min value greater than max value');
  }

  const onTexFieldChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = parseInt(event.target.value || '0');

      if (value < min) {
        value = min;
      }

      if (value > max) {
        value = max;
      }

      event.target.value = value.toString();
      onChange(name, parseInt(event.target.value || '0'));
    },
    [name, onChange]
  );

  return (
    <TextField
      label={t(`media.duration.${name}`)}
      type={'number'}
      className={classes.field}
      name={name}
      InputProps={{
        inputProps: {
          min,
          max,
        },
      }}
      variant="outlined"
      value={value.toString()}
      onChange={onTexFieldChanged}
    />
  );
};
