import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hideCheckbox: {
      flex: 1,
      marginLeft: theme.spacing(0),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
    },
  })
);
