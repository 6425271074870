import i18next from 'i18next';

import { MenuItem } from '@material-ui/core';

export const enumToNameValues = <T extends { [name: string]: any }>(enumType: T, translationPrefix?: string) => {
  const prefix = translationPrefix ? translationPrefix + '.' : '';
  return Object.keys(enumType)
    .filter((key) => Number.isNaN(Number(key)))
    .map((key) => ({
      name: translationPrefix ? i18next.t<string>(prefix + key.toLowerCase()) : key,
      value: enumType[key],
    }));
};

export const enumToMenuItems = <T extends { [name: string]: any }>(enumType: T, translationPrefix?: string) => {
  const prefix = translationPrefix ? translationPrefix + '.' : '';
  var nameValues = enumToNameValues(enumType);
  return nameValues.map((nv) => (
    <MenuItem key={nv.name} value={nv.value}>
      {i18next.t<string>(prefix + nv.name.toLowerCase())}
    </MenuItem>
  ));
};
