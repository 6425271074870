import {
  Addressing,
  AddressingActions,
  AddressingCleanUp,
  AddressingClearMode,
  AddressingSelectors,
  DefaultMediaAddressingBehavior,
} from 'addressing';
import { generateTreeIdentifier } from 'addressing/duck/utils';
import { MSG } from 'App.bootstrap';
import { FormTab, MediaForm, MediaFormList } from 'components';
import { useDirty, useDirtyDialog } from 'dirty';
import { useFormikContext } from 'formik';
import { LibrarySelectors, MessageLibraryItem } from 'library';
import React, { useCallback, useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';

import { MediaType } from '@models/media/MediaType';

import { MediaFormData } from '../../components/mediaEdit/forms';
import { formType } from '../duck';
import { AdForm } from './mediaTypes/ad';
import { AudioForm } from './mediaTypes/audio/AudioForm';
import { AggregatedAddressingRules } from '../../addressing';

export interface MessageEditFormProps {
  message?: MessageLibraryItem;
  channelId?: number;
  disableNameField?: boolean;
  onCancel?: () => void;
}

const countObjLeaves = (obj: any): number => {
  return Object.keys(obj).reduce((acc, curr) => {
    if (typeof obj[curr] === 'object') {
      return countObjLeaves(obj[curr]);
    }

    return ++acc;
  }, 0);
};

const checkEqual = (initialValues: any, values: any, rules?: AggregatedAddressingRules) => {
  const { ...a } = initialValues as any;
  const { ...b } = values as any;

  const valuesAreEqual = isEqual(a, b);
  let rulesAreEqual = true;

  if (rules) {
    rulesAreEqual = Object.keys(rules).every((treeId) => {
      return Object.keys(rules[treeId]).every((mediaId) => {
        let { originalData: ra, newData: rb } = rules[treeId][mediaId];

        rb = rb.filter((md) => md.deny !== null);
        return isEqual(ra, rb);
      });    
    });
  }

  return valuesAreEqual && rulesAreEqual;
};

export const MessageEditForm = (props: MessageEditFormProps) => {
  const dispatch = useDispatch();
  const formik = useFormikContext<MediaFormData>();
  const savingMessage = useSelector(LibrarySelectors.selectSavingMessage);
  const rules: AggregatedAddressingRules = useSelector(AddressingSelectors.selectAllAddressingRules);
  const { dirty, setDirty } = useDirty(formType);
  const { confirmClicked } = useDirtyDialog(formType);

  const resetValues = useCallback(
    (adressingClearMode: AddressingClearMode) => {
      formik.setValues({ ...formik.initialValues });

      if (!rules) {
        return;
      }

      // on cancel revert to the original (unmodified) addressing rules
      if (props.channelId && adressingClearMode === AddressingClearMode.REVERT) {
        dispatch(
          AddressingActions.addressingRulesReset(
            generateTreeIdentifier(MSG.workgroupId, props.channelId),
            props.message?.mediaInfo.id ?? 0
          )
        );
      } else {
        // if we navigate using browser history, clear the addressing entries
        const instances: Array<{treeId: string, mediaId: number}> = [];
        Object.keys(rules).forEach((treeId: string) => {
          Object.keys(rules[treeId]).forEach((mediaId: string) => {
            instances.push({treeId, mediaId: Number.parseInt(mediaId)});
          });
        });

        dispatch(AddressingActions.clearAddressingInstance(instances));
      }
    },
    [props.message, formik, rules, props.message?.mediaInfo.id, dispatch]
  );

  useEffect(() => {
    if (confirmClicked) {
      resetValues(AddressingClearMode.RESET);
      return;
    }

    const nextDirty = !checkEqual(formik.initialValues, formik.values, rules);

    if (nextDirty !== dirty) {
      setDirty(nextDirty);
    }
  }, [formik.initialValues, formik.values, formik.dirty, dirty, rules, confirmClicked, dispatch]);

  const onCancel = useCallback(() => {
    resetValues(AddressingClearMode.REVERT);
    setDirty(false);

    if (props.onCancel) {
      props.onCancel();
    }
  }, [formik, resetValues, dispatch, props.onCancel]);

  const formikErrorsCount = useMemo(() => {
    return countObjLeaves(formik.errors);
  }, [formik.errors]);

  return (
    <>
      <MediaForm saveInProgress={savingMessage} onCancel={onCancel}>
        <FormTab label="properties" errorCount={formikErrorsCount}>
          <MediaFormList>
            {props.message?.mediaInfo.mediaType === MediaType.Audio && <AudioForm media={props.message.mediaInfo} />}
            {props.message?.mediaInfo.mediaType === MediaType.Ad && <AdForm media={props.message.mediaInfo} />}
          </MediaFormList>
        </FormTab>
        {props.channelId && (
          <FormTab label="locations">
            <Addressing
              cleanupMode={AddressingCleanUp.NONE}
              channelId={props.channelId}
              mediaId={props.message?.mediaInfo.id ?? 0}
              workgroupId={MSG.workgroupId}
              defaultBehavior={DefaultMediaAddressingBehavior.Allow}
            />
          </FormTab>
        )}
      </MediaForm>
    </>
  );
};
