import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldRoot: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'top'
    },
    keyField: {
        marginRight: theme.spacing(1)
    },
    valueField: {
        marginLeft: theme.spacing(1)
    },
    input: {
        height: 'initial'
    }
  })
);
