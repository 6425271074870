import { SkeletonList } from 'components';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ListItemIcon, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PlaylistIcon from '@material-ui/icons/FormatListNumbered';

import { PlaylistsVariantProps } from '../Playlists';
import { useStyles } from './PlaylistsList.jss';

export const PlaylistsList: React.FunctionComponent<PlaylistsVariantProps> = (props) => {
  const { selected, onPlaylistSelected, items, isFetchingZonePlaylists } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const id = Number(e.currentTarget.id);
      const eventPlaylist = items.find((ep) => ep.id === id);

      if (!eventPlaylist) {
        return;
      }

      onPlaylistSelected(id);
    },
    [onPlaylistSelected, history, items]
  );

  if (isFetchingZonePlaylists) {
    return <SkeletonList minItems={3} maxItems={7} icon listClass={classes.list} divider />;
  }

  return (
    <List className={classes.list}>
      {items.map((e) => (
        <ListItem button divider key={e.id} id={e.id.toString()} selected={e.id === selected} onClick={handleClick}>
          <ListItemIcon>
            <PlaylistIcon />
          </ListItemIcon>
          <ListItemText primary={e.name} />
        </ListItem>
      ))}
    </List>
  );
};
