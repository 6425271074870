import i18 from 'i18next';
import immer from 'immer';
import { LayoutActions, LayoutActionTypes, MainLayoutArea } from 'layout';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { MediaDto, MessageType } from '@models';

import { LibraryActions } from './';
import * as Actions from './actions';
import {
  ActionTypes,
  FolderPathItem,
  LibraryState,
  MessageLibraryItem
} from './types';

type LibraryActions = ActionType<typeof Actions>;
type LayoutActions = ActionType<typeof LayoutActions>;

const initialState: LibraryState = {
  items: [],
  folderPath: [],
  selectedItem: undefined,
  fetchingMessage: false,
  fetchingLibrary: false,
  deletingMessages: false,
  savingMessage: false,
  creatingMedia: false,
};

const getRootFolderBasedOnMessageType = (library: MessageLibraryItem[], messageType: MessageType): FolderPathItem => {
  let libItem: MessageLibraryItem | undefined;
  let friendlyFolderName = '';
  switch (messageType) {
    case MessageType.Overhead:
      libItem = library.find((i) => i.mediaInfo.name === 'HARMONY_MESSAGES_OVERHEAD');
      friendlyFolderName = i18.t('overHead');
      break;
    case MessageType.OnHold:
      libItem = library.find((i) => i.mediaInfo.name === 'HARMONY_MESSAGES_ON_HOLD');
      friendlyFolderName = i18.t('onHold');
      break;
  }
  if (!libItem) {
    throw `No library folder found for message type ${messageType}`;
  } else {
    return {
      id: libItem.mediaInfo.id,
      name: friendlyFolderName,
      level: libItem.level,
      messageType,
    };
  }
};

const popFolder = (folderPath: FolderPathItem[], folderToPop: FolderPathItem | undefined) => {
  if (folderToPop) {
    const index = folderPath._findIndexBy('id', folderToPop.id);
    if (index >= 0) {
      return folderPath.slice(0, index + 1);
    }
  } else {
    if (folderPath.length > 1) {
      folderPath.pop();
    }
  }
  return folderPath;
};

const mediaDtoToLibraryItem = (mediaInfo: MediaDto, messageType: MessageType, parent?: MessageLibraryItem) => ({
  level: parent ? parent.level + 1 : 1,
  mediaInfo,
  messageType,
});

export const libraryReducer: Reducer<LibraryState, LibraryActions | LayoutActions> = (state = initialState, action) => {
  return immer(state, (draftState) => {
    let messageIndex = 0;

    switch (action.type) {
      case ActionTypes.RESET_ROOT_FOLDER: {
        const rootFolder = getRootFolderBasedOnMessageType(draftState.items, action.payload.messageType);
        draftState.folderPath = [rootFolder];
        break;
      }
      case LayoutActionTypes.MENU_SELECTION:
        if (
          action.payload.mainArea === MainLayoutArea.LibraryOnHold ||
          action.payload.mainArea === MainLayoutArea.LibraryOverhead
        ) {
          const rootFolder = getRootFolderBasedOnMessageType(draftState.items, Number(action.payload.mainArea));
          draftState.folderPath = [rootFolder];
        }
        break;
      case ActionTypes.FETCH_LIBRARY_REQUEST:
        draftState.fetchingLibrary = true;
        draftState.items = [];
        break;
      case ActionTypes.FETCH_LIBRARY_SUCCESS:
        draftState.items = action.payload.messages;
        draftState.fetchingLibrary = false;
        const rootFolder = getRootFolderBasedOnMessageType(draftState.items, action.payload.messageType);
        draftState.folderPath = [rootFolder];
        break;
      case ActionTypes.PUSH_FOLDER: {
        draftState.folderPath.push({
          id: action.payload.folder.mediaInfo.id,
          name: action.payload.folder.mediaInfo.name,
          level: action.payload.folder.level,
        });
        break;
      }
      case ActionTypes.POP_FOLDER: {
        draftState.folderPath = popFolder(draftState.folderPath, action.payload.folderPathItem);
        break;
      }
      case ActionTypes.ADD_MESSAGES:
        draftState.items = [
          ...action.payload.medias.map<MessageLibraryItem>((mediaDto) =>
            mediaDtoToLibraryItem(mediaDto, action.payload.messageType)
          ),
          ...draftState.items,
        ];
        break;
      case ActionTypes.FETCH_MESSAGE_INFO_REQUEST:
        draftState.fetchingMessage = true;
        break;
      case ActionTypes.FETCH_MESSAGE_INFO_SUCCESS:
        messageIndex = draftState.items.findIndex((i) => i.mediaInfo.id === action.payload.message.id);
        draftState.items[messageIndex].mediaInfo = action.payload.message;
        draftState.items[messageIndex].referencedChannels = action.payload.referencedChannels;
        break;
      case ActionTypes.DELETE_MESSAGES_REQUEST:
        draftState.deletingMessages = true;
        break;
      case ActionTypes.DELETE_MESSAGES_SUCCESS:
        draftState.deletingMessages = false;
        draftState.items = draftState.items.filter((m) => action.payload.mediaIds.indexOf(m.mediaInfo.id) === -1);
        break;
      case ActionTypes.SAVE_MESSAGE_INFO_REQUEST:
        draftState.savingMessage = true;
        break;
      case ActionTypes.SAVE_MESSAGE_INFO_SUCCESS:
        messageIndex = draftState.items.findIndex((msi) => msi.mediaInfo.id === action.payload.message.id);
        draftState.items[messageIndex].mediaInfo = {
          ...draftState.items[messageIndex].mediaInfo,
          ...action.payload.message,
        };
        draftState.savingMessage = false;
        break;
      case ActionTypes.SELECT_LIBRARY_ITEM:
        messageIndex = draftState.items.findIndex((msi) => msi.mediaInfo.id === action.payload);
        draftState.selectedItem = {
          item: draftState.items[messageIndex],
        };
        break;
      case ActionTypes.CLEAR_LIBRARY_SELECTION:
        draftState.selectedItem = undefined;
        break;
      case ActionTypes.SELECT_MEDIA_ADDRESSING_CHANNEL:
        if (draftState.selectedItem) {
          draftState.selectedItem.currentAddressingChannelId = action.payload.zoneId;
        }
        break;
      case ActionTypes.CREATE_MEDIA_REQUEST:
        draftState.creatingMedia = true;
        break;
      case ActionTypes.CREATE_MEDIA_SUCCESS:
        draftState.creatingMedia = false;
        draftState.items.push(mediaDtoToLibraryItem(action.payload.media, action.payload.messageType))
        break;
      default:
        return draftState;
    }
  });
};
