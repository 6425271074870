import { FormikSlider } from 'components/FormikSlider';
import React from 'react';

import { ListItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { FormListItemText } from './FormListItemText';

export interface FormListSliderItemProps {
  fieldName: string;
  label: string;
  inset?: boolean;
  icon?: React.ReactElement;
  disableGutters?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  min?: number;
  max?: number;
}

export const FormListSliderItem: React.FunctionComponent<FormListSliderItemProps> = (props) => {
  return (
    <>
      <ListItem disableGutters={props.disableGutters}>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <FormListItemText
          labelKey={props.label}
          focused={false}
          value={
            <>
              <FormikSlider fieldName={props.fieldName} min={props.min} max={props.max} />
            </>
          }
          inset={props.inset}
        />
      </ListItem>
    </>
  );
};
