import classNames from 'classnames';
import { ColumnHelpers, SkeletonList } from 'components';
import i18next from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGrid } from 'react-tools';

import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  ColDef,
  SelectionChangedEvent
} from '@ag-grid-enterprise/all-modules';

import { PlaylistsVariantProps } from '../Playlists';
import { useStyles } from './PlaylistsTable.jss';

export interface PlaylistsProps {
  onSelected?: (id: number) => void;
  selected?: number;
  zoneId: number;
  searchTerm?: string;
}

const colDefs: ColDef[] = [
  {
    headerName: i18next.t('media.name'),
    flex: 1,
    field: 'name',
    sortable: true,
    minWidth: 300,
    resizable: true,
    filter: true,
    lockPosition: true,
    lockVisible: true,
  },
  {
    headerName: i18next.t('media.creationDate'),
    field: 'creationDate',
    sortable: true,
    flex: 0,
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: ColumnHelpers.dateStringComparator,
    },
    resizable: true,
    valueFormatter: ColumnHelpers.localeDateTimeFormatter,
  },
  {
    headerName: i18next.t('media.modifiedDate'),
    field: 'modificationDate',
    sortable: true,
    flex: 0,
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: ColumnHelpers.dateStringComparator,
    },
    resizable: true,
    sort: 'desc',
    valueFormatter: ColumnHelpers.localeDateTimeFormatter,
  },
  {
    headerName: i18next.t('media.id'),
    field: 'id',
    sortable: true,
    hide: true,
    flex: 0,
    resizable: true,
  },
];

export const PlaylistsTable: React.FunctionComponent<PlaylistsVariantProps> = (props) => {
  const { selected, onPlaylistSelected, items, isFetchingZonePlaylists } = props;
  const [t] = useTranslation();
  const classes = useStyles();

  const [onGridReady] = useGrid();

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const selectedRows = event.api.getSelectedRows();
      if (selectedRows.length !== 1) {
        return;
      }
      const id = selectedRows[0].id;
      onPlaylistSelected(id);
    },
    [onPlaylistSelected]
  );

  if (isFetchingZonePlaylists) {
    return <SkeletonList minItems={3} maxItems={7} icon listClass={classes.list} divider />;
  }

  return (
    <div id="grid" className={classNames(['ag-theme-material-ui-dark', classes.gridContainer])}>
      <AgGridReact
        reactUi
        immutableData
        onGridReady={onGridReady}
        rowData={items}
        getRowNodeId={(data: any) => data.id}
        modules={AllModules}
        rowSelection="single"
        columnDefs={colDefs}
        onSelectionChanged={onSelectionChanged}
        overlayNoRowsTemplate={t('playlists.emptyList')}
        defaultCsvExportParams={{ processCellCallback: ColumnHelpers.exportProcessCellCallback }}
        defaultExcelExportParams={{ processCellCallback: ColumnHelpers.exportProcessCellCallback }}
      />
    </div>
  );
};
