import { createSelector } from 'reselect';

import { AggregatedAddressingRules } from './';
import { addressingAreaName, AddressingState, AddressingViewModes } from './types';
import { generateTreeIdentifier } from './utils';

export const selectAddressingState = (state: any): AddressingState => {
  return state[addressingAreaName];
};

export const selectAddressingInstance = (treeId: string, mediaId: number) => {
  return createSelector([selectAddressingState], (state) => state.instances?.[treeId]?.[mediaId]);
};
export const selectAddressingStructure = (treeId: string) => {
  return createSelector([selectAddressingState], (state) => state.addressingStructures[treeId]);
};
export const selectAddressingRules = (treeId: string, mediaId: number) => {
  return createSelector([selectAddressingState], (state) =>
    state.instances[treeId] ? state.instances[treeId][mediaId]?.addressingRules : undefined
  );
};
export const selectAddressingViewMode = (treeId: string, mediaId: number) => {
  return createSelector([selectAddressingState], (state) =>
    state.instances[treeId] ? state.instances[treeId][mediaId]?.viewMode : AddressingViewModes.AZ
  );
};

// select a specific set of (updated) addressing rules based on wk, channel and media
export const selectSpecificAddressingRules = (workgroupId: number, channelId: number, mediaId: number) => {
  const treeId = generateTreeIdentifier(workgroupId, channelId);
  return createSelector([selectAddressingState], (state) => {
    if (!state.instances[treeId] || !state.instances[treeId][mediaId]) {
      return [];
    }

    return state.instances[treeId][mediaId].addressingRules.newData;
  });
};

// select all updated addressing rules from all the addressing instances
export const selectAllAddressingRules = createSelector([selectAddressingState], (state) => {
  return Object.keys(state.instances).reduce<AggregatedAddressingRules>((acc, treeId) => {
    acc[treeId] = {};

    Object.keys(state.instances[treeId]).forEach((mediaId) => {
      if (state.instances[treeId] && state.instances[treeId][mediaId]) {
        acc[treeId][mediaId] = state.instances[treeId][mediaId].addressingRules;
      }
    });

    return acc;
  }, {});
});

export const selectInitializationComplete = (treeId: string, mediaId: number) => {
  return createSelector(
    [selectAddressingState],
    (state) =>
      state.addressingStructures[treeId] && (state.instances[treeId] ? state.instances[treeId][mediaId] : false)
  );
};

export const selectProcessingComplete = (treeId: string, mediaId: number) => {
  return createSelector(
    [selectAddressingState],
    (state) =>
      state.addressingStructures[treeId]?.processingStatus.complete &&
      state.instances[treeId]?.[mediaId]?.addressingRules.processingStatus.complete
  );
};
