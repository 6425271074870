import { useField } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputBaseComponentProps, ListItem, ListItemText, TextField, TextFieldProps } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export interface FormTextFieldListItemProps {
  fieldName: string;
  label?: string;
  maxLength?: number;
  inset?: boolean;
  icon?: React.ReactElement;
  disableGutters?: boolean;
  TextFieldProps?: TextFieldProps;
  regEx?: RegExp;
}

const getDefaultValue = (props: TextFieldProps | undefined): string | number => {
  if (props === undefined) {
    return '';
  }

  if (props?.inputProps?.type === 'number' || props.type === 'number') {
    return Number.isInteger(props.inputProps?.min) ? props.inputProps?.min : 0;
  }

  return '';
};

const computeInputProps = (props: TextFieldProps | undefined): InputBaseComponentProps => {
  const inputProps: InputBaseComponentProps = props?.inputProps || { style: { height: 'inherit' } };

  if (props === undefined) {
    return inputProps;
  }

  inputProps.style = inputProps.style || { height: 'inherit' };

  if (!props.type || props.type === 'text') {
    inputProps.maxLength = inputProps.maxLength || 255;
  }

  return inputProps;
};

export const FormTextFieldListItem: React.FunctionComponent<FormTextFieldListItemProps> = (props) => {
  const [field, meta, helpers] = useField(props.fieldName);
  const [cursor, setCursor] = useState({ index: 0 });
  const ref = useRef<HTMLInputElement>();
  const { min, max } = props?.TextFieldProps?.inputProps || { min: Number.MIN_VALUE, max: Number.MAX_VALUE };
  const [t] = useTranslation();

  useEffect(() => {
    if (ref && ref.current && ref.current.type !== 'number' && cursor.index) {
      ref.current.selectionStart = ref.current.selectionEnd = cursor.index - 1;
    }
  });

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const fixedCursor = e.target.selectionStart || 0;
      const advanceCursor = fixedCursor + 1;
      let value: string | number = e.target.value;

      if (e.target.type === 'text') {
        if (!props.regEx) {
          helpers.setValue(value);
          setCursor({ index: advanceCursor });
        } else {
          if (props.regEx.test(value)) {
            helpers.setValue(value);
            setCursor({ index: advanceCursor });
          } else {
            helpers.setValue(new String(field.value));
            setCursor({ index: fixedCursor });
            e.target.value = field.value;
          }
        }
      }

      if (e.target.type === 'number') {
        // convert to number
        value = parseInt(value || '0');

        if (props.regEx) {
          if (props.regEx.test(value.toString())) {
            if (value < min || value > max) {
              helpers.setValue(new String(field.value));
              e.target.value = field.value;
            }
          } else {
            helpers.setValue(field.value);
            e.target.value = field.value;
          }
        } else {
          if (value < min) {
            value = min;
          }

          if (value > max) {
            value = max;
          }

          helpers.setValue(new String(field.value));
          e.target.value = value.toString();
        }
      }

      field.onChange(e);
    },
    [helpers.setValue, props.regEx]
  );

  return (
    <ListItem disableGutters={props.disableGutters}>
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText disableTypography inset={props.inset}>
        <TextField
          inputRef={ref}
          name={props.fieldName}
          fullWidth
          variant="filled"
          label={props.label ? t(props.label) : undefined}
          error={meta.error !== undefined}
          value={field.value || getDefaultValue(props.TextFieldProps)}
          onChange={onChange}
          onBlur={field.onBlur}
          helperText={meta.error}
          {...props.TextFieldProps}
          inputProps={computeInputProps(props.TextFieldProps)}
        />
      </ListItemText>
    </ListItem>
  );
};
