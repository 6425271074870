import './localization/i18n';
import './wdyr';

import { LicenseManager } from '@ag-grid-enterprise/core';

import { setupMSG } from './App.bootstrap';
import reportWebVitals from './reportWebVitals';

LicenseManager.setLicenseKey(
  'CompanyName=Mood Media North America Holdings Corp.,LicensedApplication=Harmony,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=12,AssetReference=AG-020759,ExpiryDate=9_November_2022_[v2]_MTY2Nzk1MjAwMDAwMA==a9f6253cd39e0b7adcc2ac562782a302'
);

setupMSG();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
