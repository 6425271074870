import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { AddressingActions, AddressingCleanUp, AddressingContextData } from '../duck';
import { generateTreeIdentifier } from '../duck/utils';

const DefaultAddressingContextValue: AddressingContextData = {
  mediaId: 0,
  channelId: 0,
  workgroupId: 0,
  cleanupMode: { [AddressingCleanUp.ON_UNMOUNT]: true },
};

export const AddressingGridContext = React.createContext<AddressingContextData>(DefaultAddressingContextValue);

export const AddressingContextProvider: React.FunctionComponent<AddressingContextData> = (props) => {
  const dispatch = useDispatch();
  const { workgroupId, channelId, mediaId, cleanupMode } = props;
  const cleanUpRef = useRef(props.cleanupMode);
  cleanUpRef.current = props.cleanupMode;
  const initialWorkgroupId = useRef(workgroupId);
  const initialChannelId = useRef(channelId);
  const initialMediaId = useRef(mediaId);
  const addressingIdentifier = generateTreeIdentifier(workgroupId, channelId);
  const initialAddressingIdentifier = useRef(addressingIdentifier);
  const addressingIdentifierRef = useRef(addressingIdentifier);
  addressingIdentifierRef.current = addressingIdentifier;

  // we use a ref instead of useState because useEffect sucks when it comes to cleanup behavior
  const managedInstances = useRef<string[]>([]);

  const cleanUpPreviousAddrStructure = useCallback(
    (entity: string, refToUpdate: React.MutableRefObject<number>, updatedValue: number) => {
      if (initialAddressingIdentifier.current === addressingIdentifierRef.current) {
        return;
      }

      if (refToUpdate.current === updatedValue) {
        return;
      }

      console.info(`AddressingContext ${entity}: cleanUpPreviousAddrStructure ${initialAddressingIdentifier.current}`);
      managedInstances.current = managedInstances.current.filter((id) => id !== initialAddressingIdentifier.current);
      dispatch(AddressingActions.clearAddressingStructure([initialAddressingIdentifier.current]));

      refToUpdate.current = updatedValue;
      initialAddressingIdentifier.current = addressingIdentifierRef.current;
    },
    [dispatch]
  );

  const cleanUpPreviousInstance = useCallback(
    (entity: string, refToUpdate: React.MutableRefObject<number>, updatedValue: number) => {
      if (refToUpdate.current === updatedValue) {
        return;
      }

      console.info(
        `AddressingContext ${entity}: clearAddressingInstance ${initialAddressingIdentifier.current} mediaId ${initialMediaId.current}`
      );
      dispatch(
        AddressingActions.clearAddressingInstance([
          { treeId: initialAddressingIdentifier.current, mediaId: initialMediaId.current },
        ])
      );

      refToUpdate.current = updatedValue;
      initialAddressingIdentifier.current = addressingIdentifierRef.current;
    },
    [dispatch]
  );

  // load addressing tree structure when receiving a new workgroup-channel pair
  useEffect(() => {
    if (!managedInstances.current.includes(addressingIdentifier)) {
      managedInstances.current.push(addressingIdentifier);
    }
  }, [dispatch, addressingIdentifier]);

  // clean both addressing rules and addressing structure when unmounting this context
  // for all the managed instances of this context
  useEffect(() => {
    return () => {
      // because we are using a ref this condition will always query the most recent value of cleanUpMode
      if (cleanUpRef.current) {
        if (cleanUpRef.current[AddressingCleanUp.ON_UNMOUNT] === false) {
          console.info(
            `AddressingContext UNMOUNT: clearAddressingInstance ${addressingIdentifierRef.current} mediaId ${initialMediaId.current}`
          );
          dispatch(
            AddressingActions.clearAddressingInstance([
              { treeId: addressingIdentifierRef.current, mediaId: initialMediaId.current },
            ])
          );
        }
        if (cleanUpRef.current[AddressingCleanUp.ON_UNMOUNT] === true) {
          console.info(`AddressingContext UNMOUNT: clearAddressingFull all ${managedInstances.current.join(', ')}`);
          dispatch(AddressingActions.clearAddressingStructure(managedInstances.current));
        }
      }
    };
  }, [dispatch]);

  /* The bellow hooks have similar behavior to the ones found in AddressingComponent,
  but because AddressingComponent can be unmounted by user interaction, we sometimes
  need to do cleanup for it from this context */

  // do cleanup when workgroup changes
  useEffect(() => {
    if (cleanUpRef.current) {
      if (cleanUpRef.current[AddressingCleanUp.ON_WORKGROUP_CHANGE] === false) {
        cleanUpPreviousInstance('WORKGROUP_CHANGE', initialWorkgroupId, workgroupId);
      }
      if (cleanUpRef.current[AddressingCleanUp.ON_WORKGROUP_CHANGE] === true) {
        cleanUpPreviousAddrStructure('WORKGROUP_CHANGE', initialWorkgroupId, workgroupId);
      }
    }
  }, [props.cleanupMode, workgroupId, cleanUpPreviousInstance, cleanUpPreviousAddrStructure]);

  // do cleanup when channel changes
  useEffect(() => {
    if (cleanUpRef.current) {
      if (cleanUpRef.current[AddressingCleanUp.ON_CHANNEL_CHANGE] === false) {
        cleanUpPreviousInstance('CHANNEL_CHANGE', initialChannelId, channelId);
      }
      if (cleanUpRef.current[AddressingCleanUp.ON_CHANNEL_CHANGE] === true) {
        cleanUpPreviousAddrStructure('CHANNEL_CHANGE', initialChannelId, channelId);
      }
    }
  }, [props.cleanupMode, channelId, cleanUpPreviousInstance, cleanUpPreviousAddrStructure]);

  // do cleanup when media changes
  useEffect(() => {
    if (cleanUpRef.current) {
      if (cleanUpRef.current[AddressingCleanUp.ON_MEDIA_CHANGE] === false) {
        cleanUpPreviousInstance('MEDIA_CHANGE', initialMediaId, mediaId);
      }
      if (cleanUpRef.current[AddressingCleanUp.ON_MEDIA_CHANGE] === true) {
        cleanUpPreviousAddrStructure('MEDIA_CHANGE', initialMediaId, mediaId);
      }
    }
  }, [props.cleanupMode, mediaId, cleanUpPreviousInstance, cleanUpPreviousAddrStructure]);

  return (
    <AddressingGridContext.Provider
      value={{
        mediaId,
        channelId,
        workgroupId,
        cleanupMode,
      }}
    >
      {props.children}
    </AddressingGridContext.Provider>
  );
};
