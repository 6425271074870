import { MediaUpdateDto } from '@models';

export const sanitizeMediaUpdateDto = (media: MediaUpdateDto): MediaUpdateDto => {
  const sanitizeRegex = /((\b(exe)\b)|(\b(dll)\b)|([^\w\s\'\.\,\:\(\)\&amp;@-]))/gi;
  const sanitizedMedia = {
    ...media,
    name: media.name.replace(sanitizeRegex, ''),
    description: media.description?.replace(sanitizeRegex, ''),
  };
  return sanitizedMedia;
};

export const sanitizeExportValue = (value: string): string => {
  if(!value) {
    return '';
  }

  const sanitizeRegex = /((\b(exe)\b)|(\b(dll)\b))/gi;
  return value.replace(sanitizeRegex, '');
}

