import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';

import { ColumnHelpers } from 'components';
import i18next from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeUtils } from 'utils';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, ColDef, RowNode } from '@ag-grid-enterprise/all-modules';

import { NavigatorItemsProps } from '../types';
import { useTableSelection } from './hooks/useTableSelection';
import { MediaNameIconRenderer } from './MediaNameIconRenderer';
import { useStyles } from './NavigatorTable.jss';
import { NavigatorTableActionRenderer } from './NavigatorTableActionRenderer';

export interface NavigatorTableProps extends NavigatorItemsProps {
  controlledSelection?: number;
  controlledSelectionEnabled?: boolean;
}

const buildColDefs = (actionColumn: boolean, actionColumnLabel: string): ColDef[] => {
  const colDefs: ColDef[] = [
    {
      headerName: i18next.t('media.name'),
      flex: 1,
      field: 'mediaInfo.name',
      sortable: true,
      minWidth: 300,
      resizable: true,
      filter: true,
      lockPosition: true,
      lockVisible: true,
      cellRenderer: 'nameRenderer',
    },
    {
      headerName: i18next.t('media.duration'),
      field: 'mediaInfo.properties.trueDuration',
      sortable: true,
      flex: 0,
      valueFormatter: (params: { value: any }) => {
        const formattedValue = DateTimeUtils.secondsFormat(Number(params.value));
        return formattedValue ? formattedValue : i18next.t('unavailable');
      },
      resizable: true,
    },
    {
      headerName: i18next.t('media.creationDate'),
      field: 'mediaInfo.creationDate',
      sortable: true,
      flex: 0,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: ColumnHelpers.dateStringComparator,
      },
      sort: 'desc',
      resizable: true,
      valueFormatter: ColumnHelpers.localeDateTimeFormatter,
    },
    {
      headerName: i18next.t('media.id'),
      field: 'mediaInfo.id',
      sortable: true,
      hide: true,
      flex: 0,
      resizable: true,
    },
    {
      headerName: i18next.t('media.startDate'),
      field: 'mediaInfo.startDate',
      sortable: true,
      flex: 0,
      hide: true,
      resizable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: ColumnHelpers.localeDateFormatter,
    },
    {
      headerName: i18next.t('media.endDate'),
      field: 'mediaInfo.endDate',
      sortable: true,
      flex: 0,
      hide: true,
      resizable: true,
      valueFormatter: ColumnHelpers.localeDateFormatter,
    },
    {
      headerName: i18next.t('media.startTime'),
      field: 'mediaInfo.startTime',
      sortable: true,
      flex: 0,
      hide: true,
      resizable: true,
      valueFormatter: ColumnHelpers.localeTimeFormatter,
    },
    {
      headerName: i18next.t('media.endTime'),
      field: 'mediaInfo.endTime',
      sortable: true,
      hide: true,
      flex: 0,
      valueFormatter: ColumnHelpers.localeTimeFormatter,
    },
  ];
  if (actionColumn) {
    colDefs.push({
      colId: 'secondaryAction',
      headerName: actionColumnLabel,
      cellRenderer: 'actionRenderer',
      lockPosition: true,
      lockVisible: true,
      pinned: 'right',
      width: 120,
    });
  }
  return colDefs;
};

export const NavigatorTable: React.FunctionComponent<NavigatorTableProps> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const {
    libraryItems,
    onItemSelected,
    controlledSelection,
    controlledSelectionEnabled,
    secondaryAction,
    secondaryActionLabel,
    currentFolder,
    secondaryActionProps,
  } = props;

  const [onGridReady, onSelectionChanged] = useTableSelection({
    controlledSelectionEnabled,
    controlledSelection,
    onItemSelected,
  });

  const colDefs = useMemo(
    () => buildColDefs(secondaryAction !== undefined, secondaryActionLabel ? t(secondaryActionLabel) : ''),
    [secondaryAction]
  );

  const onPostSort = useCallback((rowNodes: RowNode[]) => {
    function move(toIndex: number, fromIndex: number) {
      rowNodes.splice(toIndex, 0, rowNodes.splice(fromIndex, 1)[0]);
    }
    let nextInsertPos = 0;
    for (let i = 0; i < rowNodes.length; i++) {
      if (rowNodes[i].data.mediaInfo.isFolder) {
        move(nextInsertPos, i);
        nextInsertPos++;
      }
    }
  }, []);

  return (
    <div className={classes.gridContainer}>
      <div id="grid" className="ag-theme-material-ui-dark" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          immutableData
          postSort={onPostSort}
          onGridReady={onGridReady}
          rowData={libraryItems}
          getRowNodeId={(data: any) => data.mediaInfo.id}
          modules={AllModules}
          rowSelection="single" //{disableSelection ? 'none' : 'single'}
          columnDefs={colDefs}
          onSelectionChanged={onSelectionChanged}
          frameworkComponents={{
            nameRenderer: MediaNameIconRenderer,
            actionRenderer: NavigatorTableActionRenderer,
          }}
          overlayNoRowsTemplate={
            currentFolder
              ? currentFolder.level > 1
                ? t('navigator.noRowsInFolder')
                : t('navigator.noRows')
              : t('navigator.noRows')
          }
          context={{
            secondaryAction: props.secondaryAction,
            secondaryActionProps: secondaryActionProps,
          }}
          defaultCsvExportParams={{ processCellCallback: ColumnHelpers.exportProcessCellCallback }}
          defaultExcelExportParams={{ processCellCallback: ColumnHelpers.exportProcessCellCallback }}
        />
      </div>
    </div>
  );
};
