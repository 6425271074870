import { isValid } from 'date-fns';
import { useField } from 'formik';
import {
  getAmPmBasedOnBrowser,
  getDateLocaleBasedOnBrowser,
  getTimeFormatBasedOnBrowser,
} from 'localization/dateLocale';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface MediaScheduleTimePickerProps {
  name: string;
  label: string;
  maxDate?: Date;
  minDate?: Date;
}

const MediaScheduleTimePicker: React.FunctionComponent<MediaScheduleTimePickerProps> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const [t] = useTranslation();
  const onChangeCallback = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined) => {
      if (!isValid(date)) {
        helpers.setError(t('media.validators.invalidDate'));
      } else {
        if (meta.error === t('media.validators.invalidDate')) {
          helpers.setError(undefined);
        }
      }

      helpers.setValue(date, true);
    },
    [helpers.setValue, meta]
  );
  const amPm = getAmPmBasedOnBrowser();
  return (
    <KeyboardTimePicker
      fullWidth
      margin="normal"
      id={props.name}
      views={['hours', 'minutes', 'seconds']}
      format={getTimeFormatBasedOnBrowser()}
      ampm={amPm}
      mask={amPm ? '__:__:__ _M' : '__:__:__'}
      label={props.label}
      value={field.value}
      onChange={onChangeCallback}
      KeyboardButtonProps={{
        'aria-label': 'change time',
      }}
      error={meta.error !== undefined}
      helperText={meta.error}
      keyboardIcon={<ScheduleIcon />}
    />
  );
};

export const MediaScheduleTime: React.FunctionComponent = (_) => {
  const [t] = useTranslation();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateLocaleBasedOnBrowser()}>
      <Grid container spacing={2}>
        <Grid item md={12} lg={6}>
          <MediaScheduleTimePicker name="scheduling.startTime" label={t('media.startTime')} />
        </Grid>
        <Grid item md={12} lg={6}>
          <MediaScheduleTimePicker name="scheduling.endTime" label={t('media.endTime')} />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
