import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemText, Typography } from '@material-ui/core';

export interface FormListItemTextProps {
  labelKey: string;
  value: React.ReactElement | string;
  noValueKey?: string;
  inset?: boolean;
  focused: boolean;
}

export const FormListItemText: React.FunctionComponent<FormListItemTextProps> = (props) => {
  const [t] = useTranslation();
  return (
    <ListItemText
      inset={props.inset}
      primaryTypographyProps={{ component: 'div' }}
      secondaryTypographyProps={{ component: 'div' }}
      primary={
        <Typography variant="caption" color={props.focused ? 'primary' : 'textSecondary'}>
          {t(props.labelKey)}
        </Typography>
      }
      secondary={
        props.value ? (
          typeof props.value === 'string' ? (
            <Typography color="textPrimary" variant="body1">
              {props.value}
            </Typography>
          ) : (
            props.value
          )
        ) : (
          <Typography color="textSecondary">{t(props.noValueKey ? props.noValueKey : 'No value')}</Typography>
        )
      }
    ></ListItemText>
  );
};
