import immer from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { MediaTypes } from '@models';

import * as Actions from './actions';
import { ActionTypes, ZoneState } from './types';

type ZoneActions = ActionType<typeof Actions>;

const initialState: ZoneState = {
  overheadZones: [],
  onHoldZones: [],
  selectedZonePlaylists: [],
  selectedPlaylistMessages: [],
  fetchingZones: false,
  fetchingPlaylist: false,
  fetchingZonePlaylists: false,
  savingPlaylist: false,
  deletingZonePlaylist: false,
  newlyCreatedPlaylistId: 0,
  initialized: false,
};

const upsertEventPlaylist = (draftState: WritableDraft<ZoneState>, eventPlaylist: MediaTypes.Playlist.PlaylistDto) => {
  const index = draftState.selectedZonePlaylists.findIndex((i) => i.id === eventPlaylist.id);
  if (index > -1) {
    draftState.selectedZonePlaylists[index] = eventPlaylist;
  } else {
    draftState.selectedZonePlaylists.push(eventPlaylist);
  }
};

export const zoneReducer: Reducer<ZoneState, ZoneActions> = (state = initialState, action) => {
  return immer(state, (draftState) => {
    switch (action.type) {
      case ActionTypes.FETCH_ZONES_REQUEST:
        draftState.fetchingZones = true;
        draftState.onHoldZones = [];
        draftState.overheadZones = [];
        draftState.initialized = false;
        break;
      case ActionTypes.FETCH_ZONES_SUCCESS:
        draftState.fetchingZones = false;
        draftState.onHoldZones = action.payload.onHoldZones.sort((a, b) => {
          if (a.zoneName < b.zoneName) {
            return -1;
          }
          if (a.zoneName > b.zoneName) {
            return 1;
          }
          return 0;
        });
        draftState.overheadZones = action.payload.overHeadZones.sort((a, b) => {
          if (a.zoneName < b.zoneName) {
            return -1;
          }
          if (a.zoneName > b.zoneName) {
            return 1;
          }
          return 0;
        });
        draftState.initialized = true;
        break;
      case ActionTypes.FETCH_ZONE_PLAYLISTS_REQUEST:
        draftState.fetchingZonePlaylists = true;
        draftState.selectedZonePlaylists = [];
        break;
      case ActionTypes.FETCH_ZONE_PLAYLISTS_SUCCESS:
        draftState.fetchingZonePlaylists = false;
        draftState.selectedZonePlaylists = action.payload.playlists;
        break;
      case ActionTypes.FETCH_ZONE_PLAYLISTS_FAIL:
        draftState.fetchingZonePlaylists = false;
        break;
      case ActionTypes.FETCH_PLAYLIST_REQUEST:
        draftState.fetchingPlaylist = true;
        break;
      case ActionTypes.FETCH_PLAYLIST_SUCCESS:
        draftState.fetchingPlaylist = false;
        upsertEventPlaylist(draftState, action.payload.playlist);
        draftState.selectedPlaylistMessages = action.payload.messages || [];
        break;
      case ActionTypes.FETCH_PLAYLIST_FAIL:
        draftState.fetchingPlaylist = false;
        break;
      case ActionTypes.SAVE_PLAYLIST_REQUEST:
        draftState.savingPlaylist = true;
        break;
      case ActionTypes.SAVE_PLAYLIST_SUCCESS:
        draftState.savingPlaylist = false;
        draftState.selectedPlaylistMessages = action.payload.messages || [];
        draftState.newlyCreatedPlaylistId = action.payload.playlistInfo.id;
        upsertEventPlaylist(draftState, action.payload.playlistInfo);
        break;
      case ActionTypes.SAVE_PLAYLIST_FAIL:
        draftState.savingPlaylist = false;
        break;
      case ActionTypes.DELETE_ZONE_PLAYLIST_SUCCESS:
        draftState.deletingZonePlaylist = false;
        draftState.selectedZonePlaylists = draftState.selectedZonePlaylists.filter(
          (e) => e.id !== action.payload.playlistId
        );
        break;
      case ActionTypes.DELETE_ZONE_PLAYLIST_REQUEST:
        draftState.deletingZonePlaylist = true;
        break;
      case ActionTypes.DELETE_ZONE_PLAYLIST_FAIL:
        draftState.deletingZonePlaylist = false;
        break;
      case ActionTypes.CLEAR_NEWLY_CREATED_PLAYLIST_ID:
        draftState.newlyCreatedPlaylistId = 0;
        break;
      case ActionTypes.SET_SELECTED_PLAYLIST:
        draftState.selectedPlaylistMessages = action.payload.messages;
        break;
      default:
        return state;
    }
  });
};
