import { action } from 'typesafe-actions';

import {
  ActionTypes,
  AddressingStructureResponse,
  AddressingViewModes,
  MediaDeny,
  MediaDenyApplyResult
} from './types';

export const fetchAddressingStructureRequest = (channelId: number, workgroupId: number, treeId: string) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_REQUEST, {
    channelId,
    workgroupId,
    treeId,
  });

export const fetchAddressingStructureSuccess = (
  channelId: number,
  workgroupId: number,
  addressingStructure: AddressingStructureResponse[],
  treeId: string
) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_SUCCESS, {
    channelId,
    workgroupId,
    addressingStructure,
    treeId
  });

export const fetchAddressingStructureError = (err: string, treeId: string) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_ERROR, { err, treeId });

export const fetchAddressingRulesRequest = (channelId: number, mediaId: number, treeId: string) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_REQUEST, { channelId, mediaId, treeId });

export const fetchAddressingRulesSuccess = (
  channelId: number,
  mediaId: number,
  addresingRules: MediaDeny[],
  treeId: string
) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_SUCCESS, {
    channelId,
    mediaId,
    treeId,
    addresingRules,
  });

export const updateAddressingTree = (treeId: string, mediaId: number) =>
  action(ActionTypes.UPDATE_ADDRESSING_TREE, { treeId, mediaId });

export const clearAddressingInstance = (targets: Array<{treeId: string, mediaId: number}>) =>
  action(ActionTypes.CLEAR_ADDRESSING_INSTANCE, targets);

export const clearAddressingStructure = (treeIds?: Array<string>) =>
  action(ActionTypes.CLEAR_ADDRESSING_STRUCTURE, treeIds);

export const fetchAddressingRulesError = (err: string, treeId: string) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_ERROR, { err, treeId });

export const saveAddressingRules = (treeId: string, mediaId: number) =>
  action(ActionTypes.SAVE_ADDRESSING_RULES, { treeId, mediaId });

export const buildAddressingTreeStart = (
  addressingStructure: AddressingStructureResponse[],
  workgroupId: number,
  treeId: string
) => action(ActionTypes.BUILD_ADDRESSING_TREE_START, { addressingStructure, workgroupId, treeId });

export const buildAddressingTreeEnd = (
  addressingStructure: string,
  treeId: string
) => action(ActionTypes.BUILD_ADDRESSING_TREE_END, { addressingStructure, treeId });

export const toggleNodeExpand = ($$hashKey: string, treeId: string, mediaId: number) =>
  action(ActionTypes.TOGGLE_NODE, { $$hashKey, treeId, mediaId });

export const processAddressingRulesStart = (newRules: MediaDeny[], treeId: string, mediaId: number) =>
  action(ActionTypes.PROCESS_ADDRESSING_RULES_START, { newRules, treeId, mediaId });

export const addressingRulesReset = (treeId: string, mediaId: number, ) =>
  action(ActionTypes.ADDRESSING_RULES_RESET, { treeId, mediaId });

export const processAddressingRulesEnd = (processedRules: MediaDenyApplyResult, treeId: string, mediaId: number, workerRequestId: string) =>
  action(ActionTypes.PROCESS_ADDRESSING_RULES_END, { processedRules, treeId, mediaId, workerRequestId });

export const subscribeToAddressingWorker = (treeId: string, mediaId: number) =>
  action(ActionTypes.SUBSCRIBE_TO_WEB_WORKER, { treeId, mediaId });

export const subscribeToHelperWorker = (treeId: string) =>
  action(ActionTypes.SUBSCRIBE_TO_HELPER_WORKER, { treeId });

export const setAddressingViewMode = (viewMode: AddressingViewModes, treeId: string, mediaId: number) =>
  action(ActionTypes.SET_ADDRESSING_VIEW_MODE, { viewMode, treeId, mediaId });

export const initAddressingInstance = (treeId: string, mediaId: number, clearIfPresent: boolean = false) =>
  action(ActionTypes.INIT_ADDRESSING_INSTANCE, { treeId, mediaId, clearIfPresent });

export const initAddressingStructure = (treeId: string, workgroupId: number, channelId: number, clearIfPresent: boolean = false) =>
  action(ActionTypes.INIT_ADDRESSING_STRUCTURE, { treeId, workgroupId, channelId, clearIfPresent });

export const cloneAddressingStructureToInstance = (treeId: string, mediaId: number) =>
  action(ActionTypes.CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE, { treeId, mediaId });
