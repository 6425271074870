import {
  DurationFields,
  FormListItem,
  FormListSliderItem,
  FormSelectListItem,
  FormTextFieldListItem,
} from 'components';
import { useField } from 'formik';
import React from 'react';
import { EnumUtils } from 'utils';

import LanguageIcon from '@material-ui/icons/Language';
import Timer from '@material-ui/icons/Timer';
import VolumeUp from '@material-ui/icons/VolumeUp';
import { MediaTypes, PlayerStreamType } from '@models';
import { AdProvider, AdsWizzCountry, AdsWizzLanguage } from '@models/media/mediaTypes/Ad';

import { AdFormCustomFields } from './AdCustomFields';
import { useStyles } from './AdForm.jss';

export interface AdFormProps {
  media: MediaTypes.Ad.AdMediaDto;
}

export const AdForm: React.FunctionComponent<AdFormProps> = (props) => {
  const [field, _, helpers] = useField('properties.adProps.duration');
  const classes = useStyles();
  return (
    <>
      <FormSelectListItem
        fieldName="properties.provider"
        label="media.ad.field.provider"
        options={EnumUtils.enumToNameValues(AdProvider, 'media.ad.providers')}
        SelectProps={{ disabled: true }}
      />
      <FormTextFieldListItem
        label="media.ad.field.accountName"
        fieldName="properties.accountName"
        regEx={/^\S*$/} // don't allow spaces
        maxLength={50}
        inset
      />
      <FormTextFieldListItem
        label="media.ad.field.alias"
        fieldName="properties.adProps.alias"
        regEx={/^\S*$/} // don't allow spaces
        inset
      />
      <FormSelectListItem
        fieldName="properties.streamType"
        label="media.ad.field.contentType"
        options={EnumUtils.enumToNameValues(PlayerStreamType, 'media.ad.contentTypes')}
        SelectProps={{ disabled: true }}
      />
      <FormListItem
        icon={<Timer />}
        labelKey="media.ad.field.adBreakDuration"
        value={
          <DurationFields classes={{ root: classes.durationRoot }} value={field.value} setValue={helpers.setValue} />
        }
      />

      <FormTextFieldListItem
        label="media.ad.field.maxAds"
        fieldName="properties.adProps.maxAds"
        inset
        TextFieldProps={{ type: 'number', inputProps: {min: 1, max: 420} }}
      />

      <FormListSliderItem
        fieldName="properties.soundVolume"
        label="media.ad.field.volume"
        icon={<VolumeUp />}
        min={0}
        max={100}
      />

      <FormSelectListItem
        icon={<LanguageIcon />}
        fieldName="properties.adProps.language"
        label="media.ad.field.language"
        options={EnumUtils.enumToNameValues(AdsWizzLanguage, 'media.ad.languages')}
      />

      <FormSelectListItem
        fieldName="properties.adProps.country"
        label="media.ad.field.country"
        options={EnumUtils.enumToNameValues(AdsWizzCountry, 'media.ad.countries')}
      />

      <AdFormCustomFields />
    </>
  );
};
