import { FormikSlider } from 'components';
import { FormListItemText } from 'components/formListItems/FormListItemText';
import { useField, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import VolumeIcon from '@material-ui/icons/VolumeUp';
import { MediaType } from '@models/media/MediaType';

import { useStyles } from './FormMediaVolumeListItem.jss';

export const FormMediaVolumeListItem: React.FunctionComponent = (props) => {
  const [field] = useField('properties.soundVolume');
  const classes = useStyles();
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <VolumeIcon />
        </ListItemIcon>
        <FormListItemText focused={false} labelKey="media.soundVolume" value={field.value} />
      </ListItem>
      <ListItem className={classes.sliderItem}>
        <ListItemText inset disableTypography>
          <FormikSlider fieldName="properties.soundVolume" className={classes.slider} />
        </ListItemText>
      </ListItem>
    </>
  );
};
