import { MasterDetails } from 'components';
import { useDirtyDialog } from 'dirty';
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DataGridContextProvider } from 'react-tools';

import { Paper } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import { AddressingCleanUp, AddressingContextProvider } from '../addressing';
import { generateTreeIdentifier } from '../addressing/duck/utils';
import useAddressingStructure from '../addressing/hooks/addressingStructure';
import { MSG } from '../App.bootstrap';
import { ConfirmationDialog } from '../components/ConfirmationDialog';
import { formType, ZoneSelectors, ZoneThunks } from './duck';
import { clearNewlyAddedPlaylistId } from './duck/actions';
import { EmptyPlaylistSelection } from './EmptyPlaylistSelection';
import { PlaylistEdit } from './playlistEdit/PlaylistEdit';
import { Playlists } from './playlists/Playlists';
import { PlaylistsToolbar } from './playlists/PlaylistsToolbar';
import { useStyles } from './ZoneEdit.jss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

interface ZoneRouteParams {
  zoneId: string;
}

export interface ZoneProps {}

export const ZoneEdit: React.FunctionComponent<ZoneProps> = (props) => {
  const { zoneId } = useParams<ZoneRouteParams>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false);
  const history = useHistory();
  const newlyAddedPlaylistId = useSelector(ZoneSelectors.selectNewlyAddedPlaylist);
  const newlyAddedPlaylist = useSelector(ZoneSelectors.selectNewlyAddedPlaylist);
  const treeId = generateTreeIdentifier(MSG.workgroupId, Number.parseInt(zoneId));
  useAddressingStructure(Number.parseInt(zoneId), MSG.workgroupId, treeId);

  const theme = useTheme();
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);

  useEffect(() => {
    if (newlyAddedPlaylistId) {
      setSelected(newlyAddedPlaylistId);
      setIsCreatingPlaylist(false);
    }
  }, [newlyAddedPlaylistId]);

  useEffect(() => {
    setSelected(newlyAddedPlaylist);
  }, [newlyAddedPlaylist]);

  useEffect(() => {
    dispatch(ZoneThunks.fetchZonePlaylists(Number(zoneId)));
    setSelected(undefined);
    setIsCreatingPlaylist(false);
  }, [zoneId, dispatch]);

  const [selected, setSelected] = useState<number | undefined>(0);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [nextSelected, setNextSelected] = React.useState(0);

  const onDirtyConfirm = useCallback(() => {
    setSelected(nextSelected);
    setNextSelected(0);
  }, [setSelected, setNextSelected, nextSelected]);

  const onDirtyCancel = useCallback(() => {
    setNextSelected(0);
  }, [setNextSelected]);

  const { dirty, clearDirty, openDialog } = useDirtyDialog(formType, onDirtyConfirm, onDirtyCancel);

  const handleSelect = useCallback(
    (id: number) => {
      if (dirty) {
        openDialog();
        setNextSelected(id);
      } else {
        setSelected(id);
      }
    },
    [dirty]
  );

  const addPlaylist = useCallback(() => {
    setSelected(undefined);
    setIsCreatingPlaylist(true);
    dispatch(clearNewlyAddedPlaylistId());
  }, [history]);

  const navigateToAddPlaylist = useCallback(() => {
    dispatch(clearNewlyAddedPlaylistId());
    history.push(`/zone/${zoneId}/create`);
  }, [history, zoneId]);

  const deletePlaylist = useCallback(() => {
    setConfirmDeleteOpen(true);
  }, [selected]);

  const onDelete = useCallback(() => {
    if (selected) {
      dispatch(ZoneThunks.deleteZonePlaylist(parseInt(zoneId), selected));
      clearDirty();
      setSelected(undefined);
      setConfirmDeleteOpen(false);
    }
  }, [selected, zoneId]);

  const onSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm]
  );
  const onCancelDelete = useCallback(() => setConfirmDeleteOpen(false), []);
  const onCancelEdit = useCallback(() => {
    setIsCreatingPlaylist(false);
    setSelected(undefined);
  }, []);

  const addressingCleanup = useMemo(() => {
    return { [AddressingCleanUp.ON_UNMOUNT]: !isSmall, [AddressingCleanUp.ON_CHANNEL_CHANGE]: true };
  }, [isSmall]);

  return (
    <AddressingContextProvider
      {...{
        mediaId: 0,
        channelId: Number.parseInt(zoneId),
        workgroupId: MSG.workgroupId,
        cleanupMode: addressingCleanup,
      }}
    >
      <div className={classes.root}>
        <Hidden mdUp>
          <PlaylistsToolbar
            onAddClick={navigateToAddPlaylist}
            onDeleteClick={onDelete}
            canDelete={!!selected}
            onSearch={onSearch}
          />
          <Playlists zoneId={parseInt(zoneId)} searchTerm={searchTerm} variant="list" />
        </Hidden>
        <Hidden smDown>
          <MasterDetails masterSize={6} detailsSize={6} emptySelection={!(selected || isCreatingPlaylist)}>
            <div className={classes.masterContainer}>
              <DataGridContextProvider>
                <PlaylistsToolbar
                  onAddClick={addPlaylist}
                  canDelete={!!selected}
                  onDeleteClick={deletePlaylist}
                  onSearch={onSearch}
                />
                <Paper className={classes.tablePaper}>
                  <Playlists
                    variant="table"
                    zoneId={parseInt(zoneId)}
                    selected={selected}
                    onSelected={handleSelect}
                    searchTerm={searchTerm}
                  />
                </Paper>
              </DataGridContextProvider>
            </div>
            {selected || isCreatingPlaylist ? (
              <PlaylistEdit playlistId={selected} zoneId={parseInt(zoneId)} onCancel={onCancelEdit} />
            ) : (
              <div className={classes.emptyPlaylistEditContainer}>
                <EmptyPlaylistSelection
                  title={'message.emptyZonePlaylistSelectionTitle'}
                  subtitle={'message.emptyZonePlaylistSelectionSubtitle'}
                />
              </div>
            )}
          </MasterDetails>
        </Hidden>
        <ConfirmationDialog
          title={t('deleteMessageConfirmTitle')}
          open={confirmDeleteOpen}
          content={t('deleteMessageContent')}
          onConfirm={onDelete}
          onCancel={onCancelDelete}
        />
      </div>
    </AddressingContextProvider>
  );
};
