import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { MediaTypes } from '@models';

import { ZoneSelectors } from '../duck';
import { PlaylistsList } from './list/PlaylistsList';
import { PlaylistsTable } from './table/PlaylistsTable';

export interface PlaylistsProps
  extends Omit<PlaylistsVariantProps, 'items' | 'isFetchingZonePlaylists' | 'onPlaylistSelected'> {
  variant: 'table' | 'list';
  onSelected?: (id: number) => void;
  zoneId: number;
  searchTerm?: string;
}

export interface PlaylistsVariantProps {
  onPlaylistSelected: (id: number) => void;
  selected?: number;

  items: MediaTypes.Playlist.PlaylistDto[];
  isFetchingZonePlaylists: boolean;
}

const PlaylistsEmpty = React.memo(() => {
  const [t] = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 0%',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography variant="subtitle2" color="textSecondary" align="center">
          {t('playlists.emptyList')}
        </Typography>
      </div>
    </div>
  );
});

export const Playlists: React.FunctionComponent<PlaylistsProps> = (props) => {
  const history = useHistory();

  const { selected, onSelected, searchTerm, variant, zoneId } = props;
  const playlists = useSelector((state) => ZoneSelectors.selectZonePlaylistsFiltered(state, searchTerm));
  const isFetchingZonePlaylists = useSelector(ZoneSelectors.selectFetchingZonePlaylists);

  const onPlaylistSelected = useCallback(
    (id: number) => {
      if (onSelected) {
        onSelected(id);
      } else {
        history.push(`/zone/${props.zoneId}/${id}`);
      }
    },
    [onSelected, history]
  );

  if (variant === 'list' && !isFetchingZonePlaylists && playlists.length === 0) {
    return <PlaylistsEmpty />;
  }

  const variantProps = { selected, searchTerm, zoneId, isFetchingZonePlaylists, onPlaylistSelected };

  return variant === 'table' ? (
    <PlaylistsTable items={playlists} {...variantProps} />
  ) : (
    <PlaylistsList items={playlists} {...variantProps} />
  );
};
