import { SkeletonListItems } from 'components';
import { ZoneIcon } from 'components/icons';
import { LayoutActions, LayoutSelectors, MainLayoutArea } from 'layout';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { ZoneSelectors, ZoneThunks } from './duck';
import { useStyles } from './ZoneList.jss';
import { AddressingActions } from '../addressing';

export interface ZoneListProps {}

export const ZoneList: React.FunctionComponent<ZoneListProps> = (props) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    dispatch(AddressingActions.clearAddressingStructure());
    dispatch(ZoneThunks.fetchZones());
  }, [dispatch]);
  const layoutState = useSelector(LayoutSelectors.selectLayoutState);

  const fetchingZones = useSelector(ZoneSelectors.selectFetchingZones);
  const zones = useSelector(ZoneSelectors.selectZones);

  useEffect(() => {
    if (!zones.overHead.length && zones.onHold.length && layoutState.selectedMainArea !== MainLayoutArea.ZonesOnHold) {
      dispatch(LayoutActions.selectMainArea(MainLayoutArea.ZonesOnHold, 0));
    } else if (
      zones.overHead.length &&
      !zones.onHold.length &&
      layoutState.selectedMainArea !== MainLayoutArea.ZonesOverhead
    ) {
      dispatch(LayoutActions.selectMainArea(MainLayoutArea.Unauthorized, 0));
    }
  }, [layoutState, zones]);

  const zoneList = useMemo(() => {
    if (zones.overHead.length && layoutState.selectedMainArea === MainLayoutArea.ZonesOverhead) {
      return zones.overHead;
    }

    if (zones.onHold.length && layoutState.selectedMainArea === MainLayoutArea.ZonesOnHold) {
      return zones.onHold;
    }

    if (!zones.overHead.length && zones.onHold.length) {
      return zones.onHold;
    }

    if (!zones.onHold.length && zones.overHead.length) {
      return zones.overHead;
    }

    return [];
  }, [layoutState, zones]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const id = Number(e.currentTarget.id);

      const zone = zoneList.find((z) => z.zoneId === id);
      if (!zone) {
        return;
      }
      dispatch(LayoutActions.selectMainArea(layoutState.selectedMainArea, id));
      history.push(`/zone/${id}`);
    },
    [zones, history, zoneList, layoutState.selectedMainArea]
  );

  const onTabsChanged = useCallback(
    (e: React.ChangeEvent<{}>, value: any) => {
      dispatch(LayoutActions.selectMainArea(value as MainLayoutArea, 0));
    },
    [dispatch]
  );

  return (
    <div className={classes.container}>
      <Tabs value={layoutState.selectedMainArea} onChange={onTabsChanged}>
        {zones.overHead.length > 0 && <Tab label={t('overHead')} value={MainLayoutArea.ZonesOverhead} />}
        {zones.onHold.length > 0 && <Tab label={t('onHold')} value={MainLayoutArea.ZonesOnHold} />}
      </Tabs>

      <List className={classes.list}>
        {fetchingZones ? (
          <SkeletonListItems minItems={2} maxItems={4} divider />
        ) : (
          zoneList.map((z) => (
            <ListItem id={z.zoneId.toString()} key={z.zoneId} button divider onClick={handleClick}>
              <ListItemIcon>
                <ZoneIcon />
              </ListItemIcon>
              <ListItemText primary={z.zoneName} />
            </ListItem>
          ))
        )}
      </List>
    </div>
  );
};
