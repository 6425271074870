import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    field: {
        marginLeft: theme.spacing(2),
        maxWidth: theme.spacing(6.5),
    },
    fieldRoot: {
        paddingBottom: theme.spacing(1),
        borderRadius: theme.spacing(0.5)
    },
    fieldInput: {
        marginTop: theme.spacing(-0.5),
        textAlign: 'right'
    }
  });

export const useStyles = makeStyles(styles);
