import { MSG } from 'App.bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType } from 'react-tools';

import { FormControlLabel, IconButton, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';

import { GreenSwitch } from './components/GreenSwitch';
import { AddressingGridContext } from './context/addressing.context';
import { AddressingActions } from './duck';
import { selectSpecificAddressingRules } from './duck/selectors';
import {
  AddressingContextData,
  DefaultMediaAddressingBehavior,
  MediaDeny
} from './duck/types';
import { generateTreeIdentifier } from './duck/utils';

export interface WorkgroupRuleSwitchProps {
  defaultBehavior: DefaultMediaAddressingBehavior;
}

const determineAllowSwitch = (
  workgroupRule: MediaDeny | undefined,
  defaultBehavior: DefaultMediaAddressingBehavior
): boolean => {
  if (!workgroupRule || workgroupRule.deny === null) {
    return defaultBehavior === DefaultMediaAddressingBehavior.Allow;
  }
  return !workgroupRule.deny;
};

const determineWorkgroupRuleDeny = (allowChecked: boolean, defaultBehavior: DefaultMediaAddressingBehavior) => {
  if (allowChecked) {
    return defaultBehavior === DefaultMediaAddressingBehavior.Allow ? null : false;
  } else {
    return defaultBehavior === DefaultMediaAddressingBehavior.Allow ? true : null;
  }
};

export const WorkgroupRuleSwitch: React.FunctionComponent<WorkgroupRuleSwitchProps> = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { defaultBehavior } = props;
  const [allowByDefault, setAllowByDefault] = useState(false);
  const addressingInfo = useContext<AddressingContextData>(AddressingGridContext);
  const treeId = generateTreeIdentifier(
    addressingInfo.workgroupId,
    addressingInfo.channelId
  );

  const addressingRules = useSelector(
    selectSpecificAddressingRules(addressingInfo.workgroupId, addressingInfo.channelId, addressingInfo.mediaId)
  );

  useEffect(() => {
    const workgroupRule = addressingRules.find(
      (r) =>
        r.idEntity === MSG.workgroupId &&
        r.idEntityType === EntityType.Workgroup &&
        r.idMedia === addressingInfo.mediaId
    );
    const allow = determineAllowSwitch(workgroupRule, defaultBehavior);
    setAllowByDefault(allow);
  }, [addressingRules, addressingInfo.mediaId, setAllowByDefault, defaultBehavior]);

  const onSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const rule: MediaDeny = {
        idMediaChannel: addressingInfo.channelId,
        idMedia: addressingInfo.mediaId,
        idEntity: MSG.workgroupId,
        idEntityType: EntityType.Workgroup,
        deny: determineWorkgroupRuleDeny(checked, defaultBehavior),
      };
      setAllowByDefault(checked);
      dispatch(AddressingActions.processAddressingRulesStart([rule], treeId, addressingInfo.mediaId));
    },
    [addressingInfo, treeId, setAllowByDefault, defaultBehavior]
  );

  return (
    <div>
      <FormControlLabel
        control={<GreenSwitch checked={allowByDefault} onChange={onSwitchChange} />}
        label={t('addressing.defaultBehavior.allowByDefault')}
      />
      <Tooltip
        title={t<string>(
          `addressing.defaultBehavior.help.${
            props.defaultBehavior === DefaultMediaAddressingBehavior.Allow ? 'allow' : 'deny'
          }`
        )}
      >
        <IconButton>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
