import { DurationFields, FormListSwitchItem } from 'components';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { DateTimeUtils } from 'utils';

import { ListItem, ListItemText } from '@material-ui/core';
import Timer from '@material-ui/icons/Timer';

const DEFAULT_DURATION = 10000;

export const FormMediaDurationListItem: React.FunctionComponent = (props) => {
  const [field, _, helpers] = useField('duration');

  const onSwitchChange = useCallback(
    (on: boolean) => {
      helpers.setValue(on ? DEFAULT_DURATION : undefined);
    },
    [helpers]
  );

  return (
    <FormListSwitchItem
      on={field?.value !== undefined}
      labelKey={'duration'}
      noValueKey="media.duration.error.unavailable"
      value={field.value ? DateTimeUtils.secondsFormat(field.value) : ''}
      icon={<Timer />}
      onSwitchChange={onSwitchChange}
    >
      <ListItem>
        <ListItemText inset disableTypography primary={<DurationFields value={field.value} setValue={helpers.setValue} />} />
      </ListItem>
    </FormListSwitchItem>
  );
};


