import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddressingService from 'addressing/addressingService';

import { AddressingActions, AddressingThunks, AddressingSelectors } from '../duck';

const useAddressingStructure = (channelId: number = 0, workgroupId: number = 0, treeId: string) => {
  const dispatch = useDispatch();
  const addressingStructureState = useSelector(AddressingSelectors.selectAddressingStructure(treeId));

  // effect used to init addressingStructure state
  useEffect(() => {
    // if there's no state for this addressing structure (treeId), create one
    if (!addressingStructureState) {
      dispatch(AddressingActions.initAddressingStructure(treeId, workgroupId, channelId));
    }
  }, [addressingStructureState, workgroupId, channelId, treeId]);

  // effect used to load addressing structure data
  useEffect(() => {
    // if there's no state for this addressing instance (treeId), abort
    if (!addressingStructureState) {
      return;
    }

    const isFetching = addressingStructureState.fetchStatus.isFetching;
    const fetchComplete = addressingStructureState.fetchStatus.complete;
    const fetchError = addressingStructureState.fetchStatus.error;

    // we need to fetch addressing structure
    if (!isFetching && !fetchComplete && (fetchError === '')) {
      dispatch(AddressingThunks.fetchAddressingStructure(channelId, workgroupId, treeId));
    }
  }, [
    addressingStructureState,
    channelId,
    workgroupId,
    treeId,
    dispatch,
  ]);


  // subscribe to the Helper Worker so it can send us back the generated addressing view modes
  useEffect(() => {
    // if there's no state for this addressing structure (treeId), abort
    if (!addressingStructureState) {
      return;
    }
    
    if (addressingStructureState.subscribedToHelperWorker) {
      return;
    }

    AddressingService.subscribeToHelperWorker((result: string) => {
      dispatch(AddressingActions.buildAddressingTreeEnd(result, treeId));
    }, treeId);

    dispatch(AddressingActions.subscribeToHelperWorker(treeId));
  }, [addressingStructureState, channelId, workgroupId]);

  return {
    processedData: addressingStructureState?.processedData,
    fetchStatus: addressingStructureState?.fetchStatus,
    processingStatus: addressingStructureState?.processingStatus
  };
};

export default useAddressingStructure;
