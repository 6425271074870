import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      marginTop: theme.spacing(4),
      flexDirection: 'column',
      height: 'calc(100% - 64px)',
      overflow: 'auto',
    },
    gridContainer: {
      height: '100%',
      width: '100%',
    },
  })
);
