import { addMinutes } from 'date-fns';
import format from 'date-fns/format';

export const secondsFormat = (totalMiliseconds: number | undefined) => {
  if (!totalMiliseconds) {
    return '';
  }
  const totalSeconds = totalMiliseconds / 1000;
  const seconds = Math.floor(totalSeconds % 60);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes > 59 ? totalMinutes % 60 : totalMinutes;
  const hours = Math.floor(totalMinutes / 60);
  return `${hours.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${minutes.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
};

export const minutesFormat = (totalMinutes: number | undefined) => {
  if (!totalMinutes) {
    return '00:00:00';
  }
  const minutes = totalMinutes > 59 ? totalMinutes % 60 : totalMinutes;
  const hours = Math.floor(totalMinutes / 60);
  return `${hours.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${minutes.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:00`;
};

export const secondsFormatForRepeatFrequency = (totalSeconds: number | undefined) => {
  if (!totalSeconds) {
    return '00:00:01';
  }

  return secondsFormat(totalSeconds * 1000);
};

export const minutesAndSecondsFormat = (totalMinutes: number | undefined, totalSeconds: number | undefined) => {
  const innerTotalMinutes = totalMinutes ? totalMinutes : 0;
  const innerTotalSeconds = totalSeconds ? totalSeconds : 0;

  if (!totalMinutes && !totalSeconds) {
    return '00:00:01';
  }

  const minutes = innerTotalMinutes > 59 ? innerTotalMinutes % 60 : innerTotalMinutes;
  const hours = Math.floor(innerTotalMinutes / 60);

  return `${hours.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${minutes.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${innerTotalSeconds.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}`;
};

export const getHMSFromMS = (value: number): number[] => {
  const hours = Math.floor((value / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((value / (1000 * 60)) % 60);
  const seconds = Math.floor((value / 1000) % 60);

  return [hours, minutes, seconds];
};

export const getValueWithLimits = (value: number, min: number, max: number): number =>
  Math.min(Math.max(min, value), max);

export const getMSFromHMS = (hours: number, minutes: number, seconds: number): number =>
  (hours * 60 * 60 + minutes * 60 + seconds) * 1000;

export const convertHMSToMinutes = (hms?: string | number): number => {
  if (!hms) {
    return 0;
  }

  if (typeof hms === 'number') {
    return hms;
  }

  const [hours, minutes] = hms.split(':');
  return parseInt(hours) * 60 + parseInt(minutes);
};

export const convertHMSToSeconds = (hms?: string | number): number => {
  if (!hms) {
    return 0;
  }

  if (typeof hms === 'number') {
    return hms;
  }

  const [hours, minutes, seconds] = hms.split(':');
  return (parseInt(hours) * 60 + parseInt(minutes)) * 60 + parseInt(seconds);
};

export const isStartOfDay = (date: Date): boolean =>
  date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;

export const isEndOfDay = (date: Date): boolean =>
  date.getHours() === 23 && date.getMinutes() === 59 && date.getSeconds() === 59;
