import { EntityType } from 'react-tools';
import { FixedSizeNodeData } from 'react-vtree';

export enum TreeItemState {
  InheritAllow = 0,
  Allow = 1,
  Deny = 2,
  InheritDeny = 3,
}

export interface AddressingStructure {
  uniqueTreeId: string;
  label: string;
  level: number;
  entityType: EntityType;
  modelType: TreeItemType;
  modelId: string;
  parentModelId: string;
  cleanParentId: string;
  isExpanded: boolean;
  isSelected: boolean;
  children: AddressingStructure[];
  metadata: string;
  childCount: number;
  hasChildren: boolean;
  $$hashKey: string;
  filteredIn: boolean;
  chckState: number;
  cleanId: number;
  link?: boolean;
  linked?: boolean;
}

export enum TreeItemType {
  Site = 'Site',
  Player = 'Player',
  Workgroup = 'Workgroup',
  Group = 'Group',
  SiteGroup = 'SiteGroup',
  Stream = 'Stream',
  FOLDER = 'FOLDER',
}

export interface MediaDeny {
  idMediaChannel: number;
  idMedia: number;
  idEntity: number;
  idEntityType: EntityType;
  deny: boolean | null;
}

export interface AddressingStructureResponse {
  id: string;
  parent: string;
  name: string;
  level: number;
  entityType: number;
  link: boolean;
}

interface WorkerResponse<T> {
  message: string;
  requestId: string;
  data: T;
}

export type MediaDenyApplyWorkerResponse = WorkerResponse<MediaDenyApplyResult>;
export type AddressingHelperWorkerResponse = WorkerResponse<string>;

export interface MediaDenyApplyResult {
  bucketGroups: { [key: string]: number };
  bucketSites: { [key: string]: number };
  bucketPlayers: { [key: string]: number };
  bucketStream: { [key: string]: number };
  bucketWorkgroups: { [key: string]: number };
}

export enum AddressingViewModes {
  AZ = 0,
  Workgroups = 1,
  Groups = 2,
}

export enum DefaultMediaAddressingBehavior {
  Deny = 0,
  Allow = 1,
}

export interface AddressingState {
  addressingStructures: { [key: string]: AddressingStructureState };
  instances: {
    [key: string]: { [key: string]: AddressingInstanceState };
  };
}

export interface AddressingInstanceState {
  addressingTree: AddressingStructure[][];
  liniarAddressingTree: Array<{ [key: string]: number[] }>;
  addressingRules: AddressingRulesState;
  needToUpdateTree: boolean;
  mediaId: number;
  viewMode: AddressingViewModes;
  subscribedToAddressingWorker: boolean;
  workerRequestId: string;
}

export interface AddressingContextData {
  mediaId: number;
  channelId: number;
  workgroupId: number;
  cleanupMode?: { [key in AddressingCleanUp]?: boolean };
}

interface AddressingData<T, K> {
  originalData: T;
  processedData: K;
  processingStatus: {
    isProcessing: boolean;
    complete: boolean;
  };
  fetchStatus: {
    isFetching: boolean;
    complete: boolean;
    error: string;
  };
}

export interface AggregatedAddressingRules {
  [key: string]: { [key: string]: AddressingRulesState };
}

export interface AddressingStructureState extends AddressingData<AddressingStructureResponse[], string> {
  workgroupId: number;
  channelId: number;
  subscribedToHelperWorker: boolean;
}

export interface AddressingRulesState extends AddressingData<MediaDeny[], MediaDenyApplyResult> {
  newData: MediaDeny[];
}

export type TreeNode = Readonly<AddressingStructure>;

export type TreeData = FixedSizeNodeData &
  Readonly<{
    isLeaf: boolean;
    label: string;
    nestingLevel: number;
    isExpanded: boolean;
    chckState: number;
    modelType: TreeItemType;
    isProcessing: boolean;
    isLinked: boolean;
    onToggle: () => void;
    onChange: () => void;
  }>;

export type NodeMeta = Readonly<{
  nestingLevel: number;
  node: TreeNode;
}>;

export enum AddressingCleanUp {
  NONE = 0,
  ON_WORKGROUP_CHANGE = 1,
  ON_CHANNEL_CHANGE = 2,
  ON_MEDIA_CHANGE = 4,
  ON_UNMOUNT = 8,
  ON_ALL = 15,
}

export enum AddressingClearMode {
  RESET = 1,
  REVERT,
}

export const addressingAreaName = 'ADDRESSING_AREA';

export enum ActionTypes {
  SUBSCRIBE_TO_WEB_WORKER = 'SUBSCRIBE_TO_WEB_WORKER',
  SUBSCRIBE_TO_HELPER_WORKER = 'SUBSCRIBE_TO_HELPER_WORKER',
  FETCH_ADDRESSING_STRUCTURE_REQUEST = 'FETCH_ADDRESSING_STRUCTURE_REQUEST',
  FETCH_ADDRESSING_STRUCTURE_SUCCESS = 'FETCH_ADDRESSING_STRUCTURE_SUCCESS',
  FETCH_ADDRESSING_STRUCTURE_ERROR = 'FETCH_ADDRESSING_STRUCTURE_ERROR',
  CLEAR_ADDRESSING_STRUCTURE = 'CLEAR_ADDRESSING_STRUCTURE',
  FETCH_ADDRESSING_RULES_REQUEST = 'FETCH_ADDRESSING_RULES_REQUEST',
  FETCH_ADDRESSING_RULES_SUCCESS = 'FETCH_ADDRESSING_RULES_SUCCESS',
  FETCH_ADDRESSING_RULES_ERROR = 'FETCH_ADDRESSING_RULES_ERROR',
  SAVE_ADDRESSING_RULES = 'SAVE_ADDRESSING_RULES',
  BUILD_ADDRESSING_TREE_START = 'BUILD_ADDRESSING_TREE_START',
  BUILD_ADDRESSING_TREE_END = 'BUILD_ADDRESSING_TREE_END',
  PROCESS_ADDRESSING_RULES_START = 'PROCESS_ADDRESSING_RULES_START',
  ADDRESSING_RULES_RESET = 'ADDRESSING_RULES_RESET',
  PROCESS_ADDRESSING_RULES_END = 'PROCESS_ADDRESSING_RULES_END',
  CLEAR_ADDRESSING_INSTANCE = 'CLEAR_ADDRESSING_INSTANCE',
  UPDATE_ADDRESSING_TREE = 'UPDATE_ADDRESSING_TREE',
  TOGGLE_NODE = 'TOGGLE_NODE',
  SET_ADDRESSING_VIEW_MODE = 'SET_ADDRESSING_VIEW_MODE',
  INIT_ADDRESSING_INSTANCE = 'INIT_ADDRESSING_INSTANCE',
  INIT_ADDRESSING_STRUCTURE = 'INIT_ADDRESSING_STRUCTURE',
  CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE = 'CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE',
}
