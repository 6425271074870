import { DropdownButton, GridExportButton, Toolbar } from 'components';
import { FolderPathItem } from 'library/duck/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGridQuickSearchEvent } from 'react-tools';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import { MediaType } from '@models/media/MediaType';

import { NavigatorBreadcrumbs } from './NavigatorBreadcrumbs';
import { useStyles } from './NavigatorToolbar.jss';

export interface NavigatorToolbarProps {
  currentFolder: FolderPathItem;
  hideControls?: boolean;
  hideSearch?: boolean;
  onSearchChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  deleteDisabled?: boolean;
  onSort: (prop: 'name' | 'creationDate') => void;
  onFolderBack: () => void;
  onDelete: () => void;
  onNewMedia: (mediaType: MediaType) => void;
  adProviderEnabled: boolean;
}

export const NavigatorToolbar: React.FunctionComponent<NavigatorToolbarProps> = React.memo((props) => {
  const { currentFolder, onFolderBack, onDelete, onNewMedia, deleteDisabled } = props;
  const classes = useStyles();
  const quickSearch = useGridQuickSearchEvent();
  const [mediaOptions, setMediaOptions] = useState<Array<{ labelKey: string; value: MediaType }>>([
    { labelKey: 'Audio file', value: MediaType.Audio },
  ]);

  const [t] = useTranslation();

  const handleQuicksearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (quickSearch) {
        quickSearch(e.target.value);
      }
    },
    [quickSearch]
  );

  useEffect(() => {
    if (props.adProviderEnabled) {
      setMediaOptions([
        { labelKey: 'Audio file', value: MediaType.Audio },
        {
          labelKey: 'Ad Provider',
          value: MediaType.Ad,
        },
      ]);
    } else {
      setMediaOptions([{ labelKey: 'Audio file', value: MediaType.Audio }]);
    }
  }, [props.adProviderEnabled]);

  return (
    <Toolbar
      helpType="library"
      onSearch={props.onSearchChange ? props.onSearchChange : handleQuicksearch}
      hideSearch={props.hideSearch}
    >
      <div className={classes.currentFolderContainer}>
        {currentFolder?.level > 1 && (
          <IconButton size="small" className={classes.backFolderButton} onClick={onFolderBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography className={classes.currentFolderTitle} variant="h5">
          {currentFolder?.name}
        </Typography>
      </div>
      <>
        {!props.hideControls && (
          <>
            <DropdownButton onClick={onNewMedia} options={mediaOptions} />
            <GridExportButton fileName={t('export.fileNames.messages')} />
            <IconButton disabled={deleteDisabled} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </>
      <NavigatorBreadcrumbs typographyProps={{ variant: 'caption' }} classes={{ root: classes.breadcrumbsRoot }} />
    </Toolbar>
  );
});
