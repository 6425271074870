import immer from 'immer';
import deepClone from 'lodash-es/cloneDeep';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import AddressingService from '../addressingService';
import * as Actions from './actions';
import {
  ActionTypes,
  AddressingInstanceState,
  AddressingRulesState,
  AddressingState,
  AddressingStructureState,
  AddressingViewModes,
} from './types';
import { applyDeniesOnTree, flattenTree } from './utils';

type AddressingActions = ActionType<typeof Actions>;

const initialAddressingRulesState: AddressingRulesState = {
  originalData: [],
  processedData: {
    bucketGroups: {},
    bucketSites: {},
    bucketPlayers: {},
    bucketStream: {},
    bucketWorkgroups: {},
  },
  newData: [],
  processingStatus: {
    isProcessing: false,
    complete: false,
  },
  fetchStatus: {
    isFetching: false,
    complete: false,
    error: '',
  },
};

const initialAddressingStructureState: AddressingStructureState = {
  originalData: [],
  processedData: '',
  workgroupId: 0,
  channelId: 0,
  subscribedToHelperWorker: false,
  processingStatus: {
    isProcessing: false,
    complete: false,
  },
  fetchStatus: {
    isFetching: false,
    complete: false,
    error: '',
  },
};

const initialAddressingInstanceState: AddressingInstanceState = {
  addressingTree: [],
  liniarAddressingTree: [],
  addressingRules: { ...initialAddressingRulesState },
  needToUpdateTree: true,
  mediaId: 0,
  viewMode: AddressingViewModes.AZ,
  subscribedToAddressingWorker: false,
  workerRequestId: '',
};

const initialAddressingState: AddressingState = {
  addressingStructures: {},
  instances: {},
};

const addressingStructureActions = [
  ActionTypes.FETCH_ADDRESSING_STRUCTURE_REQUEST,
  ActionTypes.FETCH_ADDRESSING_STRUCTURE_ERROR,
  ActionTypes.FETCH_ADDRESSING_STRUCTURE_SUCCESS,
  ActionTypes.BUILD_ADDRESSING_TREE_START,
  ActionTypes.BUILD_ADDRESSING_TREE_END,
  ActionTypes.SUBSCRIBE_TO_HELPER_WORKER,
  ActionTypes.CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE,
];

const addressingInstanceActions = [,
  ActionTypes.FETCH_ADDRESSING_RULES_REQUEST,
  ActionTypes.FETCH_ADDRESSING_RULES_ERROR,
  ActionTypes.FETCH_ADDRESSING_RULES_SUCCESS,
  ActionTypes.ADDRESSING_RULES_RESET,
  ActionTypes.PROCESS_ADDRESSING_RULES_START,
  ActionTypes.PROCESS_ADDRESSING_RULES_END,
  ActionTypes.UPDATE_ADDRESSING_TREE,
  ActionTypes.TOGGLE_NODE,
  ActionTypes.SUBSCRIBE_TO_WEB_WORKER,
  ActionTypes.SET_ADDRESSING_VIEW_MODE,
  ActionTypes.SAVE_ADDRESSING_RULES,
  ActionTypes.CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE,
];

export const addressingReducer: Reducer<AddressingState, AddressingActions> = (
  state = initialAddressingState,
  action
) => {
  const treeId = (action.payload as any)?.treeId;

  /* 
  Most of the bellow actions require an addressing instance (a.k.a. treeId)
  to do their job, but it is not always guaranteed that that treeId is still available.

  Take for example PROCESS_ADDRESSING_RULES_START and PROCESS_ADDRESSING_RULES_END.
  Depending on how the AddressingComponent is used and various user actions, the same
  addressing instance used in PROCESS_ADDRESSING_RULES_START might not be available when
  PROCESS_ADDRESSING_RULES_END is called. The instance was probably removed intentionally.
  Since we no longer have an addressing instance with the provided id, we just return the
  current state without changing anything.
   */
  if (treeId) {
    if (!state.addressingStructures[treeId] && addressingStructureActions.includes(action.type)) {
      console.info(`Structure action '${action.type}' missing treeId ${treeId}`);
      return state;
    }

    if (!state.instances[treeId] && addressingInstanceActions.includes(action.type)) {
      console.info(`Instance action '${action.type}' missing treeId ${treeId}`);
      return state;
    }
  } else {
    // if there's no treeId, then we have a problem
    if (addressingStructureActions.includes(action.type) || addressingInstanceActions.includes(action.type)) {
      throw new Error(`Missing treeId '${treeId}' in '${action.type}' action`); 
    }
  }

  let mediaId: number;

  return immer(state, (draftState) => {
    switch (action.type) {
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_REQUEST:
        draftState.addressingStructures[treeId].fetchStatus.isFetching = true;
        draftState.addressingStructures[treeId].fetchStatus.complete = false;
        draftState.addressingStructures[treeId].fetchStatus.error = '';
        draftState.addressingStructures[treeId].workgroupId = action.payload.workgroupId;
        draftState.addressingStructures[treeId].channelId = action.payload.channelId;
        break;
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_SUCCESS:
        draftState.addressingStructures[treeId].originalData = deepClone(action.payload.addressingStructure);
        draftState.addressingStructures[treeId].fetchStatus.isFetching = false;
        draftState.addressingStructures[treeId].fetchStatus.complete = true;
        draftState.addressingStructures[treeId].fetchStatus.error = '';
        break;
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_ERROR:
        draftState.addressingStructures[treeId].originalData = [];
        draftState.addressingStructures[treeId].fetchStatus.complete = false;
        draftState.addressingStructures[treeId].fetchStatus.isFetching = false;
        draftState.addressingStructures[treeId].fetchStatus.error = action.payload.err;
        draftState.addressingStructures[treeId].workgroupId = 0;
        draftState.addressingStructures[treeId].channelId = 0;
        break;
      case ActionTypes.BUILD_ADDRESSING_TREE_START:
        AddressingService.initAddressingStructure(
          action.payload.addressingStructure,
          action.payload.workgroupId,
          treeId
        );
        AddressingService.initAddressingWorker(action.payload.addressingStructure, action.payload.workgroupId, treeId);
        draftState.addressingStructures[treeId].processingStatus.isProcessing = true;
        draftState.addressingStructures[treeId].processingStatus.complete = false;
        break;
      case ActionTypes.BUILD_ADDRESSING_TREE_END:
        const trees = action.payload.addressingStructure;
        draftState.addressingStructures[treeId].processedData = trees;
        draftState.addressingStructures[treeId].processingStatus.isProcessing = false;
        draftState.addressingStructures[treeId].processingStatus.complete = true;
        AddressingService.removeHelperWorker(treeId);
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_REQUEST:
        if (!draftState.instances[treeId]) {
          throw new Error(`Addressing tree structure not initialized for ${treeId}`);
        }

        mediaId = action.payload.mediaId;

        draftState.instances[treeId][mediaId].addressingRules.fetchStatus.isFetching = true;
        draftState.instances[treeId][mediaId].addressingRules.fetchStatus.complete = false;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_SUCCESS:
        mediaId = action.payload.mediaId;
        draftState.instances[treeId][mediaId].addressingRules.originalData = deepClone(action.payload.addresingRules);
        draftState.instances[treeId][mediaId].addressingRules.newData = deepClone(action.payload.addresingRules);
        draftState.instances[treeId][mediaId].addressingRules.fetchStatus.isFetching = false;
        draftState.instances[treeId][mediaId].addressingRules.fetchStatus.complete = true;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_ERROR:
        draftState.instances[treeId][mediaId].addressingRules.fetchStatus.isFetching = false;
        draftState.instances[treeId][mediaId].addressingRules.fetchStatus.complete = false;
        draftState.instances[treeId][mediaId].addressingRules.fetchStatus.error = action.payload.err;
        draftState.instances[treeId][mediaId].addressingRules.originalData = [];
        break;
      case ActionTypes.ADDRESSING_RULES_RESET:
        mediaId = action.payload.mediaId;

        if (!draftState.instances[treeId]?.[mediaId]) {
          break;
        }

        const originalRules = deepClone(state.instances[treeId][mediaId].addressingRules.originalData);
        draftState.instances[treeId][mediaId].addressingRules.processingStatus.isProcessing = true;
        draftState.instances[treeId][mediaId].addressingRules.processingStatus.complete = false;
        draftState.instances[treeId][mediaId].addressingRules.newData = deepClone(originalRules);
        draftState.instances[treeId][mediaId].workerRequestId = AddressingService.applyRules(originalRules, treeId);
        break;
      case ActionTypes.PROCESS_ADDRESSING_RULES_START:
        if (
          action.payload.newRules.length > 0 &&
          action.payload.newRules.findIndex((r) => r.idMedia !== action.payload.newRules[0].idMedia) !== -1
        ) {
          throw new Error('All items in the addressing rules array must have the same media id');
        }

        mediaId = action.payload.mediaId;

        draftState.instances[treeId][mediaId].addressingRules.newData = action.payload.newRules.reduce((a, r) => {
          let idx = a.findIndex(
            (addrRule) =>
              addrRule.idEntity === r.idEntity &&
              addrRule.idMedia === r.idMedia &&
              addrRule.idEntityType === r.idEntityType
          );

          if (idx === -1) {
            a.push(r);
          } else {
            a[idx] = r;
          }
          return a;
        }, draftState.instances[treeId][mediaId].addressingRules.newData);

        const updatedRules = deepClone(draftState.instances[treeId][mediaId].addressingRules.newData);

        draftState.instances[treeId][mediaId].addressingRules.processingStatus.isProcessing = true;
        draftState.instances[treeId][mediaId].addressingRules.processingStatus.complete = false;
        const rulesToProcess = updatedRules.filter((md) => md.deny !== null);
        draftState.instances[treeId][mediaId].workerRequestId = AddressingService.applyRules(rulesToProcess, treeId);
        break;
      case ActionTypes.PROCESS_ADDRESSING_RULES_END:
        mediaId = action.payload.mediaId;

        // because there's only one addressing worker handling requests from multiple addressingInstances,
        // we need to check that the response from the worker matches the request of this specific addressingInstance
        if (draftState.instances[treeId][mediaId].workerRequestId !== action.payload.workerRequestId) {
          break;
        }

        draftState.instances[treeId][mediaId].addressingRules.processingStatus.isProcessing = false;
        draftState.instances[treeId][mediaId].addressingRules.processingStatus.complete = true;
        draftState.instances[treeId][mediaId].needToUpdateTree = true;
        draftState.instances[treeId][mediaId].addressingRules.processedData = deepClone(action.payload.processedRules);
        draftState.instances[treeId][mediaId].workerRequestId = '';
        break;
      case ActionTypes.UPDATE_ADDRESSING_TREE:
        mediaId = action.payload.mediaId;

        applyDeniesOnTree(
          draftState.instances[treeId][mediaId].addressingTree,
          draftState.instances[treeId][mediaId].addressingRules.processedData
        );

        draftState.instances[treeId][action.payload.mediaId].needToUpdateTree = false;
        break;
      case ActionTypes.TOGGLE_NODE:
        mediaId = action.payload.mediaId;
        draftState.instances[treeId][mediaId].liniarAddressingTree.forEach((flatTree, treeIndex) => {
          const indexes = flatTree[action.payload.$$hashKey];

          if (!indexes) {
            return ;
          }

          const rootIndex = indexes[0] || 0;

          const node = indexes.slice(1).reduce((acc, value) => {
            return acc.children[value];
          }, draftState.instances[treeId][mediaId].addressingTree[treeIndex][rootIndex]);
          
          // this should never happen, but can't hurt to check
          if (!node || node.$$hashKey !== action.payload.$$hashKey) {
            return;
          }

          node.isExpanded = !node.isExpanded;
          console.info(`tree_${treeId}_media_${mediaId}: ${node.$$hashKey}: ${node.isExpanded ? 'expanded' : 'collapsed'}`);
        });
        break;
      case ActionTypes.SUBSCRIBE_TO_WEB_WORKER:
        mediaId = action.payload.mediaId;
        if (!draftState.instances[treeId][mediaId].subscribedToAddressingWorker) {
          draftState.instances[treeId][mediaId].subscribedToAddressingWorker = true;
        }
        break;
      case ActionTypes.SUBSCRIBE_TO_HELPER_WORKER:
        if (!draftState.addressingStructures[treeId].subscribedToHelperWorker) {
          draftState.addressingStructures[treeId].subscribedToHelperWorker = true;
        }
        break;
      case ActionTypes.CLEAR_ADDRESSING_INSTANCE:
        action.payload.forEach((addressingInstance) => {
          const { treeId, mediaId } = addressingInstance;

          if (!draftState.instances?.[treeId]?.[mediaId]) {
            return;
          }

          AddressingService.unSubscribeFromAddressingWorker(treeId, mediaId);
          delete draftState.instances[treeId][mediaId];
        });
        break;
      case ActionTypes.CLEAR_ADDRESSING_STRUCTURE: //TODO: should rename this to CLEAR_ADDRESSING_BOTH ('cause it clears both structure and related instances)
        const treeIds = action.payload !== undefined ? action.payload : Object.keys(state.addressingStructures);

        treeIds.forEach((treeId) => {
          // if we have any instances for this treeId, clean them up
          if (draftState.instances[treeId]) {
            const instanceIds = Object.keys(draftState.instances[treeId]);

            instanceIds.forEach((mediaId: string) => {
              AddressingService.removeAddressingWorker(treeId);
              delete draftState.instances[treeId][mediaId];
            });
          }

        treeIds.forEach((treeId) => {
          // it's normal to have addressingWorkers active, but no addressing instances,
          // so we need to make sure we also clear out any remaining addressingWorkers
          AddressingService.removeAddressingWorker(treeId);
        });

          AddressingService.removeHelperWorker(treeId);
          delete draftState.instances[treeId];
          delete draftState.addressingStructures[treeId];
        });
        break;
      case ActionTypes.SET_ADDRESSING_VIEW_MODE:
        draftState.instances[treeId][action.payload.mediaId].viewMode = action.payload.viewMode;
        break;
      case ActionTypes.SAVE_ADDRESSING_RULES:
        mediaId = action.payload.mediaId;

        if (!draftState.instances[treeId]?.[mediaId]) {
          break;
        }

        const newOriginalData = draftState.instances[treeId][mediaId].addressingRules.newData.filter(
          (md) => md.deny !== null
        );
        draftState.instances[treeId][mediaId].addressingRules.originalData = deepClone(newOriginalData);
        break;
      case ActionTypes.INIT_ADDRESSING_STRUCTURE:
        if (!treeId) {
          throw new Error(`Missing treeId '${treeId}' in '${action.type}' action`); 
        }

        if (draftState.addressingStructures[treeId] && !action.payload.clearIfPresent) {
          break;
        }

        const { workgroupId, channelId } = action.payload;
        draftState.addressingStructures[treeId] = { ...initialAddressingStructureState, workgroupId, channelId };
        break;
      case ActionTypes.INIT_ADDRESSING_INSTANCE:
        mediaId = action.payload.mediaId;

        if (!treeId) {
          throw new Error(`Missing treeId '${treeId}' in '${action.type}' action`); 
        }

        if (!draftState.instances[treeId]) {
          draftState.instances[treeId] = {};
        }

        if (draftState.instances[treeId][mediaId] && !action.payload.clearIfPresent) {
          break;
        }

        draftState.instances[treeId][mediaId] = { ...initialAddressingInstanceState, mediaId };
        break;
      case ActionTypes.CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE:
        mediaId = action.payload.mediaId;

        if (!draftState.addressingStructures[treeId].processingStatus.complete) {
          throw new Error(
            `Processing for addressing structure treeId '${treeId}' not complete in CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE`
          );
        }

        if (!draftState.instances[treeId][mediaId]) {
          throw new Error(
            `Missing addressing instance treeId '${treeId}' with mediaId '${mediaId}' in CLONE_ADDRESSING_STRUCTURE_TO_INSTANCE`
          );
        }

        draftState.instances[treeId][mediaId].addressingTree = JSON.parse(
          draftState.addressingStructures[treeId].processedData
        );

        draftState.instances[treeId][mediaId].addressingTree.forEach((tree, treeIndex) => {
          const flat = {};
          
          tree.forEach((node, childIndex) => {
            flattenTree(node, flat, [childIndex])
          });

          draftState.instances[treeId][mediaId].liniarAddressingTree.push(flat);
        });

        break;
      default:
        return state;
    }
  });
};
