import { FieldArray, FieldInputProps, useField } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TextFieldsIcon from '@material-ui/icons/TextFields';

import { useStyles } from './AdCustomFields.jss';

export const AdFormCustomFields: React.FunctionComponent<{}> = (props) => {
  const [t] = useTranslation();
  const fieldName = 'properties.adProps.customProps';
  return (
    <FieldArray
      name={fieldName}
      render={(arrayHelpers) => (
        <>
          <ListItem>
            <ListItemIcon>
              <TextFieldsIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="caption" color="textSecondary">
                  {t('media.ad.field.customProps')}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => arrayHelpers.push({ id: -1, key: 'NewKey', value: 'NewValue' })}>
                <AddIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText disableTypography inset>
              {arrayHelpers.form.getFieldProps(fieldName).value?.map((item: any, index: number) => (
                <AdField key={index} index={index} removeItem={() => arrayHelpers.remove(index)} />
              ))}
            </ListItemText>
          </ListItem>
        </>
      )}
    />
  );
};

const AdField: React.FunctionComponent<{ index: number; removeItem: () => void }> = (props) => {
  const classes = useStyles();
  const keyRef = useRef<HTMLInputElement>();
  const valueRef = useRef<HTMLInputElement>();
  const [cursor, setCursor] = useState({ index: 0 });

  const [keyField, keyMeta] = useField(`properties.adProps.customProps[${props.index}].key`);
  const [valueField, valueMeta] = useField(`properties.adProps.customProps[${props.index}].value`);

  // effect used to reposition the cursor after manipulating input value in onChange
  useEffect(() => {
    const ref =
      keyRef.current === document.activeElement
        ? keyRef
        : valueRef.current === document.activeElement
        ? valueRef
        : undefined;

    if (ref && ref.current && cursor.index) {
      ref.current.selectionStart = ref.current.selectionEnd = cursor.index - 1;
    }
  });

  const ignoreSpacesOnChange = useCallback((fProps: FieldInputProps<any>) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;

      if (value.includes(' ')) {
        setCursor({ index: e.target.selectionStart || 0 });
        e.target.value = value.replace(/\s+/g, '');
      }

      fProps.onChange(e);
    };

    return onChange;
  }, []);

  return (
    <div className={classes.fieldRoot}>
      <TextField
        inputRef={keyRef}
        inputProps={{ className: classes.input, maxLength: 50 }}
        variant="filled"
        className={classes.keyField}
        value={keyField.value || ''}
        onChange={ignoreSpacesOnChange(keyField)}
        name={keyField.name}
        label="Key"
        error={keyMeta.error !== undefined}
        helperText={keyMeta.error}
      />
      <TextField
        inputRef={valueRef}
        inputProps={{ className: classes.input, maxLength: 50 }}
        variant="filled"
        className={classes.valueField}
        value={valueField.value || ''}
        onChange={ignoreSpacesOnChange(valueField)}
        name={valueField.name}
        label="Value"
        error={valueMeta.error !== undefined}
        helperText={valueMeta.error}
      />
      <IconButton onClick={props.removeItem}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
