import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    intervalWrapper: {
      display: 'flex',
    },
    field: {
      '&:nth-child(2)': {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
    },
  });

export const useStyles = makeStyles(styles);
